
.page-news {
  .kv {
    background: url(../img/news/key_visual.png) no-repeat center center;
    background-size: cover;
    height: auto;
    padding-top: 27%;
  }
  .content {
    margin: 120px 0;
    @include sp-screen {
      margin: 60px 0;
    }
  }
  .post-cont {
    font-size: 18px;
    @include sp-screen {
      font-size: 16px;
    }
    a {
      color: #38acbd;
      border-bottom: 1px solid #38acbd;
    }
  }
}

@include sp-screen {}

.content {
  counter-reset: number;
  &__navi {
    display: flex;
    justify-content: space-between;
    margin-bottom: 70px;
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 236px;
      height: 80px;
      @include rem-font-size(19);
      letter-spacing: 0.11em;
      border: solid 1px $mainColor;
      border-radius: 4px;
      position: relative;
      &:before {
        content:"";
        width: 35px;
        height: 32px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        position: absolute;
        left: 10px;
        top: 50%;
        margin-top: -16px;
      }
      span {
        display: inline-block;
        position: relative;
        z-index: 1;
        padding-bottom: 2px;
      }
    }
    @include sp-screen {
      flex-wrap: wrap;
      &.wrap {
        margin: 0 10px 20px;
      }
      a {
        width: 48.59%;
        height: 60px;
        margin-bottom: 10px;
        @include rem-font-size(14);
        letter-spacing: 0.06em;
      }
    }
  }
  .block {
    // margin-bottom: 120px;
    // @include sp-screen {
    //   margin-bottom: 60px;
    // }
    &__ttl {
      color: $white;
      margin-bottom: 50px;
      @include rem-font-size(30);
      letter-spacing: 0.01em;
      text-align: center;
      overflow: hidden;
      &.blue {
        .in {
          &::before,
          &::after {
            background-color: $mainColor;
          }
        }
      }
      &.green {
        .in {
          &::before,
          &::after {
            background-color: #008e5c;
          }
        }
      }
      &.light-green {
        .in {
          &::before,
          &::after {
            background-color: #88c23f;
          }
        }
      }
      @include sp-screen {
        margin-bottom: 26px;
        @include rem-font-size(21);
      }
      .in {
        position: relative;
        display: inline-block;
        vertical-align: top;
        line-height: 1.5;
        &::before,
        &::after {
          content: '';
          display: block;
          position: absolute;
          bottom: 0%;
          width: 100%;
          height: 110%;
          border-radius: 4px;
        }
        &::before {
          left: -15px;
          transform: skewX(25deg);
        }
        &:after {
          right: -15px;
          transform: skewX(-25deg);
        }
        span {
          display: inline-block;
          width: 280px;
          position: relative;
          z-index: 1;
        }
        @include sp-screen {
          padding-bottom: 2px;
          &::before,
          &::after {
            border-radius: 2px;
          }
          span {
            width: 200px;
          }
        }
      }
    }
    &__list {
      list-style: none;
      font-size: 1.6rem;
    }
  }
}

body.home {
  .content {
    padding-top: 124px;
    @include sp-screen {
      padding-top: 0;
    }
  }
}
.page-outpatient {
  .kv {
    // background: url(../img/outpatient/key_visual.png) no-repeat center center;
    // background-size: cover;
    width: 100%;
    height: calc(100vh - 124px);
    padding-top: 0;
    @include sp-screen {
      height: 200px;
    }
    ul {
      width: 100%;
      li.slide {
        width: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        a {
          display: block;
          width: 100%;
          height: calc(100vh - 124px);
          position: relative;
          .title {
            position: absolute;
            left: 0;
          }
        }
        &.slide1 {
          background-image: url(../img/top/key_visual01.png);
          .title {
            width: 62.97%;
            top: 119px;
          }
        }
        &.slide2 {
          background-image: url(../img/top/key_visual02.png);
          .title {
            width: 52.38%;
            top: 119px;
          }
        }
        &.slide3 {
          background-image: url(../img/top/key_visual03.png);
          .title {
            width: 55.95%;
            bottom: 140px;
          }
        }
        &.slide4 {
          background-image: url(../img/top/key_visual04.png);
          .title {
            width: 53.69%;
            top: 120px;
          }
        }
      }
      @include sp-screen {
        li.slide {
          a {
            height: 200px;
          }
          &.slide1 {
            background-image: url(../img/top/key_visual01_sp.png);
            .title {
              width: 73.33%;
              top: 19px;
            }
          }
          &.slide2 {
            background-image: url(../img/top/key_visual02_sp.png);
            .title {
              width: 61.86%;
              top: 19px;
            }
          }
          &.slide3 {
            background-image: url(../img/top/key_visual03_sp.png);
            .title {
              width: 61.6%;
              bottom: 27px;
            }
          }
          &.slide4 {
            background-image: url(../img/top/key_visual04_sp.png);
            .title {
              width: 62.93%;
              top: 19px;
            }
          }
        }
      }
    }
    .slick-arrow {
      &.slick-prev,
      &.slick-next {
        width: 60px;
        height: 60px;
        border-top: solid 4px $white;
        position: absolute;
        top: 50%;
        z-index: 1;
        &:before {
          display: none;
        }
      }
      &.slick-prev {
        transform: rotate(-45deg);
        border-left: solid 4px $white;
        left: 50px;
      }
      &.slick-next {
        transform: rotate(45deg);
        border-right: solid 4px $white;
        right: 50px;
      }
      @include sp-screen {
        &.slick-prev,
        &.slick-next {
          width: 14px;
          height: 14px;
          border-top: solid 2px $white;
          margin-top: -4px;
        }
        &.slick-prev {
          border-left: solid 2px $white;
          left: 10px;
        }
        &.slick-next {
          border-right: solid 2px $white;
          right: 10px;
        }
      }
    }
  }
  .wrap {
    @include pc-screen {
      width: 980px;
      margin-left: auto;
      margin-right: auto;
    }
    @include sp-screen {
      width: auto;
      margin-left: 5%;
      margin-right: 5%;
    }
  }
  .content {
    &__ttl {
      padding: 45px 0 30px;
      text-align: center;
      @include rem-font-size(36);
      font-weight: normal;
      letter-spacing: 0.12em;
      @include sp-screen {
        padding: 15px 0;
        @include rem-font-size(21);
      }
    }
    &__navi {
      a {
        &:nth-of-type(1) {
          &:before {
            background-image: url(../img/top/icon_06.png);
          }
        }
        &:nth-of-type(2) {
          letter-spacing: 0.07em;
          &:before {
            background-image: url(../img/top/icon_07.png);
          }
          span {
            padding-left: 40px;
          }
        }
        &:nth-of-type(3) {
          &:before {
            background-image: url(../img/top/icon_08.png);
          }
          span {
            padding-left: 20px;
          }
        }
        &:nth-of-type(4) {
          &:before {
            background-image: url(../img/top/icon_09.png);
          }
        }
      }
      @include sp-screen {
        a {
          &:nth-of-type(1) {
            &:before {
              width: 23px;
            }
          }
          &:nth-of-type(2) {
            letter-spacing: 0.07em;
            &:before {
              width: 22px;
            }
            span {
              padding-left: 30px;
            }
          }
          &:nth-of-type(3) {
            &:before {
              width: 22px;
            }
            span {
              padding-left: 10px;
            }
          }
          &:nth-of-type(4) {
            &:before {
              width: 24px;
            }
          }
        }
      }
    }
  }
  .outpatient-block {
    background: rgba($mainColor, 0.1);
    padding-bottom: 40px;
    .wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .photo {
      margin-right: 40px;
    }
    .information {
      width: 504px;
      letter-spacing: 0.11em;
      .title {
        color: $mainColor;
        @include rem-font-size(30);
      }
      .txt {
        margin-top: 20px;
        @include rem-font-size(17.5);
        line-height: 31.5px;
      }
      .btn-more {
        margin-top: 20px;
        text-align: right;
        @include rem-font-size(14);
      }
    }
    @include sp-screen {
      padding-bottom: 20px;
      .wrap {
        display: block;
      }
      .photo {
        margin-right: 0;
        text-align: center;
        img {
          width: 100%;
          max-width: 265px;
        }
      }
      .information {
        width: 100%;
        margin-top: 10px;
        .title {
          text-align: center;
          @include rem-font-size(21);
        }
        .txt {
          margin-top: 10px;
          @include rem-font-size(14);
          line-height: 27.5px;
        }
        .btn-more {
          margin-top: 5px;
        }
      }
    }
  }
  .subjects {
    width: 495px;
    margin-bottom: 16px;
    @include sp-screen {
      width: 100%;
      margin-bottom: 0;
      margin-top: 20px;
    }
    dt {
      margin-bottom: 10px;
      padding-bottom: 10px;
      @include rem-font-size(20);
      border-bottom: dotted 2px #c5c5c5;
      letter-spacing: 0.12em;
      @include sp-screen {
        @include rem-font-size(18);
      }
      &.icon_course {
        display: flex;
        align-items: center;
        &::before {
          content: "";
          display: block;
          width: 42px;
          height: 35px;
          margin: 0 10px 0 0;
          background: url(../img/common/icon_course.png) no-repeat center;
          background-size: contain;
        }
      }
      &.icon_physical-therapy {
        display: flex;
        align-items: center;
        &::before {
          content: "";
          display: block;
          width: 42px;
          height: 35px;
          margin: 0 10px 0 0;
          background: url(../img/common/icon_physical-therapy.png) no-repeat center;
          background-size: contain;
        }
      }
      &.icon_closed {
        display: flex;
        align-items: center;
        &::before {
          content: "";
          display: block;
          width: 42px;
          height: 35px;
          margin: 0 10px 0 0;
          background: url(../img/common/icon_closed.png) no-repeat center;
          background-size: contain;
        }
      }
    }
    dd {
      @include rem-font-size(16);
      line-height: 1.5;
      letter-spacing: 0.14em;
      @include sp-screen {
        @include rem-font-size(14);
      }
      a {
        color: $mainColor;
      }
    }
  }
  .schedule-block {
    background: rgba($mainColor, 0.1);
    padding-bottom: 120px;
    @include sp-screen {
      padding-bottom: 30px;
      .wrap {
        margin: 0;
      }
    }
    .outpatient {
      @include pc-screen {
        display: flex;
        justify-content: space-between;
        width: 854px;
        margin: 35px auto 30px;
        dl {
          width: 422px;
          padding: 25px 25px 10px;
          background: $white;
          box-sizing: border-box;
        }
      }
      @include sp-screen {
        width: auto;
        margin: 0 5% 30px;
      }
      display: flex;
      justify-content: space-between;
      @include sp-screen {
        display: block;
      }
    }
    .table-wrap {
      .schedule {
        width: 100%;
        @include rem-font-size(16);
        border: solid 1px #c5c5c5;
        @include sp-screen {
          width: 100%;
          @include rem-font-size(14);
        }
        .bg-main {
          background: $mainColor;
          color: $white;
          padding: 20px 20px 22px;
          border-right: solid 1px $white;
          border-bottom: none;
          &:last-child {
            border: none;
          }
        }
        th, td {
          background: $white;
          padding: 15px 0px 10px;
          text-align: center;
          border-bottom: solid 1px #c5c5c5;
          border-right: solid 1px #c5c5c5;
          line-height: 35px;
          vertical-align: top;
          letter-spacing: 0;
          @include rem-font-size(22);
          &.item {
            padding-left: 20px;
            text-align: left;
            @include rem-font-size(22);
            p {
              line-height: 35px;
              margin-bottom: 30px;
            }
            .gray {
              @include rem-font-size(22);
            }
          }
          &:last-child {
            border-right: none;
          }
          .big {
            @include rem-font-size(25);
          }
          .sml {
            @include rem-font-size(17);
          }
          .bold {
            font-weight: bold;
          }
          p {
            margin-bottom: 15px;
            line-height: 26px;
          }
        }
        td {
          .blue {
            @include rem-font-size(17);
            span {
              @include rem-font-size(13.5);
            }
          }
          @include sp-screen {
            @include rem-font-size(16);
          }
        }
        span.align-l {
          display: inline-block;
          margin-top: 5px;
          padding: 0 5px;
          text-align: left;
        }
        .blue {
          color: $mainColor;
        }
        .gray {
          color: #666;
          @include rem-font-size(18);
          &.sml {
            @include rem-font-size(14);
          }
        }
      }
      @include sp-screen {
        overflow: scroll;
        -webkit-overflow-scrolling: touch;
        overflow-scrolling: touch;
        margin: 0 5%;
        .schedule {
          width: 670px;
          @include rem-font-size(14);
          border: solid 1px #c5c5c5;
          .bg-main {
            padding: 12px 10px 14px;
          }
          th, td {
            background: $white;
            padding: 15px 0px 10px;
            line-height: 22px;
            vertical-align: top;
            letter-spacing: 0;
            @include rem-font-size(14);
            &.item {
              padding-left: 10px;
              text-align: left;
              @include rem-font-size(14);
              p {
                line-height: 22px;
                margin-bottom: 15px;
              }
              .gray {
                @include rem-font-size(14);
              }
            }
            &:last-child {
              border-right: none;
            }
            .big {
              @include rem-font-size(16);
            }
            .sml {
              @include rem-font-size(13);
            }
            p {
              margin-bottom: 10px;
              line-height: 22px;
            }
          }
          td {
            .blue {
              @include rem-font-size(11);
              line-height: 1.38;
              span {
                @include rem-font-size(10);
              }
            }
          }
          span.align-l {
            display: inline-block;
            margin-top: 5px;
            padding: 0 5px;
            text-align: left;
          }
          .blue {
            color: $mainColor;
          }
          .gray {
            color: #666;
            @include rem-font-size(14);
            &.sml {
              @include rem-font-size(10);
            }
          }
        }
      }
    }
    .notes {
      margin-top: 20px;
      @include rem-font-size(20);
      overflow: hidden;
      line-height: 35.5px;
      dt {
        float: left;
        color: $mainColor;
        margin-right: 5px;
      }
      dd {
        overflow: hidden;
      }
      @include sp-screen {
        padding: 0 5%;
        @include rem-font-size(14);
        line-height: 1.5;
      }
    }
  }
  .news-block {
    margin-bottom: 90px;
    @include sp-screen {
      margin-bottom: 20px;
    }
  }
  .news {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    @include sp-screen {
      display: block;
      align-items: flex-start;
      overflow: hidden;
    }
    &__date {
      margin-right: 2em;
      @include rem-font-size(13);
      @include sp-screen {
        float: left;
        margin-right: 1.5em;
        width: 90px;
      }
    }
    &__text {
      @include rem-font-size(16);
      @include sp-screen {
        overflow: hidden;
        word-break: break-all;
        @include rem-font-size(14);
        text-align: left;
      }
    }
  }
  .news-more {
    text-align: right;
    @include rem-font-size(14);
  }
  a.btn-text {
    position: relative;
    display: inline-block;
    padding: 0 12px 0 0;
    vertical-align: middle;
    text-decoration: none;
    &::after {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 2px;
      margin: auto;
      vertical-align: middle;
      content: "";
      width: 6px;
      height: 6px;
      border-top: 2px solid $mainColor;
      border-right: 2px solid $mainColor;
      transform: rotate(45deg);
    }
  }
  .btn-block {
    text-align: center;
    a.btn-frame {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 340px;
      height: 94px;
      margin-left: auto;
      margin-right: auto;
      @include rem-font-size(30);
      letter-spacing: 0.11em;
      border: solid 2px $mainColor;
      border-radius: 6px;
      position: relative;
      span {
        display: inline-block;
        position: relative;
        &:before {
          content:"";
          border-top: 12px solid transparent;
          border-left: 16px solid $mainColor;
          border-bottom: 12px solid transparent; 
          position: absolute;
          right: -24px;
          top: 50%;
          margin-top: -10px;
        }
      }
    }
    @include sp-screen {
      a.btn-frame {
        @include rem-font-size(14);
        width: 170px;
        height: 47px;
        span {
          &:before {
            content:"";
            border-top: 7px solid transparent;
            border-left: 10px solid $mainColor;
            border-bottom: 7px solid transparent;
            right: -20px;
            margin-top: -7px;
          }
        }
      }
    }
  }
  .staff-block {
    padding-bottom: 94px;
    @include sp-screen {
      padding-bottom: 50px;
    }
    .staff {
      display: flex;
      margin-bottom: 50px;
      @include sp-screen {
        flex-direction: column;
        margin-bottom: 20px;
      }
      &:last-child {
        margin-bottom: 60px;
      }
      &__img {
        width: 200px;
        margin-right: 40px;
        overflow: hidden;
        @include sp-screen {
          margin: 0 auto 15px;
          width: 122px;
        }
        img {
          width: 100%;
        }
      }
      &__text-area {
        width: calc(100% - 180px);
        @include rem-font-size(16);
        line-height: 1.5;
        @include sp-screen {
          width: 100%;
          @include rem-font-size(14);
        }
      }
      &__item {
        margin-bottom: calc(1em * 1.5);
        @include rem-font-size(20);
        &:last-child {
          margin-bottom: 0;
        }
        dd {
          overflow: hidden;
          .year {
            float: left;
            margin-right: 2em;
          }
          .txt {
            overflow: hidden;
          }
        }
        @include sp-screen {
          margin-bottom: 15px;
          @include rem-font-size(15);
        }
      }
      &__head {
        color: $mainColor;
        @include rem-font-size(18);
        @include sp-screen {
          @include rem-font-size(14);
        }
      }
      &__name {
        padding-bottom: 10px;
        border-bottom: solid 1px $mainColor;
        @include rem-font-size(22.5);
        span {
          @include rem-font-size(30);
          margin-right: 0.5em;
        }
        @include sp-screen {
          @include rem-font-size(13.5);
          span {
            @include rem-font-size(18);
          }
        }
      }
      &__tag {
        margin-top: 15px;
        p {
          display: inline-block;
          color: $white;
          margin-right: 15px;
          padding: 5px 10px 7px;
          @include rem-font-size(20);
          line-height: 1;
          &.green {
            background-color: #84bf40;
          }
          &.blue {
            background-color: $mainColor;
          }
        }
        @include sp-screen {
          margin-top: 10px;
          p {
            @include rem-font-size(15);
            margin-right: 5px;
          }
        }
      }
    }
  }
  .access-block {
    background: rgba($mainColor, 0.1);
    padding-bottom: 114px;
    .wrap {
      display: flex;
    }
    .map {
      width: 400px;
      margin: 0 auto;
      text-align: center;
      img {
        display: block;
        width: 100%;
      }
    }
    .information {
      background: $white;
      width: 580px;
      padding: 25px 40px 20px;
      box-sizing: border-box;
    }
    .address,
    .info {
      @include rem-font-size(20);
      line-height: 30px;
      letter-spacing: 0.14em;
      @include sp-screen {
        @include rem-font-size(13);
        line-height: 1.7;
      }
    }
    .address {
      margin-bottom: 15px;
    }
    .info {
      &__ttl {
        color: $mainColor;
        @include rem-font-size(17.5);
      }
      &__text {
        //display: flex;
        margin-bottom: 20px;
        a {
          color: $mainColor;
        }
        &::before {
          content: "□";
        }
      }
    }
    @include sp-screen {
      padding-bottom: 57px;
      .wrap {
        display: block;
      }
      .map {
        width: 100%;
      }
      .information {
        width: 100%;
        padding: 25px 18px 20px;
      }
      .info {
        &__ttl {
          @include rem-font-size(14);
        }
        &__text {
          margin-bottom: 20px;
          a {
            color: $mainColor;
          }
          &::before {
            content: "□";
          }
        }
      }
    }
  }
  .diagnosis {
    width: 100%;
    padding-bottom: 120px;
    .wrap {
      max-width: 850px;
    }
    .btn-block {
      margin-top: 40px;
      text-align: left;
      a {
        margin-left: 0;
      }
    }
    @include sp-screen {
      padding-bottom: 60px;
      .btn-block {
        a {
          margin-left: auto;
        }
      }
    }
  }
  .diagnosis-block {
    display: flex;
    flex-wrap: wrap;
    margin-top: 75px;
    @include sp-screen {
      display: block;
    }
  }
  .diagnosis-item {
    display: block;
    width: 45%;
    margin-bottom: 80px;
    &:last-child {
      margin-bottom: 0;
    }
    @include sp-screen {
      width: 100%;
      height: auto;
      margin-bottom: 30px;
    }
    &:nth-child(odd) {
      margin-right: auto;
    }
    &__ttl {
      color: $mainColor;
      @include rem-font-size(30);
      text-align: center;
      .in {
        display: flex;
        align-items: center;
        img {
          width: 80px;
        }
        .txt {
          margin-left: 20px;
        }
      }
      @include sp-screen {
        @include rem-font-size(20);
        .in {
          display: inline-block;
          position: relative;
          img {
            position: absolute;
            width: 40px;
            left: -50px;
            top: 50%;
            margin-top: -20px;
          }
          .txt {
            margin-left: 0;
          }
        }
      }
    }
    &__txt {
      margin-top: 10px;
      @include rem-font-size(16);
      @include sp-screen {
        font-size: 14px;
      }
      &--link a {
        color: $mainColor;
        font-size: 12px;
      }
    }
  }
}

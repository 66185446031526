@charset "UTF-8";
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline; }

html {
  line-height: 1; }

ol, ul {
  list-style: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle; }

q, blockquote {
  quotes: none; }

q:before, q:after, blockquote:before, blockquote:after {
  content: "";
  content: none; }

a img {
  border: none; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block; }

.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  font-size: 0;
  clear: both;
  visibility: hidden; }

html, body {
  font-size: 10px; }

body {
  font-family: 'Noto Sans JP', sans-serif;
  line-height: 1.7;
  color: #333;
  min-width: 1024px; }
  @media screen and (max-width: 769px) {
    body {
      min-width: inherit; } }

.pc-on {
  display: block; }

.sp-on {
  display: none; }

a {
  text-decoration: none;
  transition: all 0.5s;
  color: #333; }

img {
  height: auto !important; }

@media screen and (min-width: 770px) {
  a img {
    transition: opacity 0.5s;
    backface-visibility: hidden; }
  a:hover {
    opacity: 0.7;
    text-decoration: none; }
  a[href^="tel:"] {
    pointer-events: none; }
  .post-cont {
    font-size: 16px; } }

@media screen and (max-width: 769px) {
  .pc-on {
    display: none; }
  .sp-on {
    display: block; }
  img {
    width: 100%; }
  input[type="text"],
  input[type="button"],
  input[type="email"],
  input[type="submit"],
  input[type="password"],
  textarea {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -webkit-appearance: none;
    max-width: 100%;
    border-radius: 0; } }

.sp-logo {
  display: none; }
  @media screen and (max-width: 769px) {
    .sp-logo {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 6px 0; } }

.logo {
  line-height: 1; }
  @media screen and (max-width: 769px) {
    .logo {
      margin-bottom: 16px; } }
  .logo__sub {
    display: block;
    font-size: 17px;
    font-size: 1.7rem;
    margin-bottom: 1.2rem;
    letter-spacing: 0.04em; }
    @media screen and (max-width: 769px) {
      .logo__sub {
        margin-bottom: 0.6rem;
        font-size: 12px;
        font-size: 1.2rem; } }
  .logo__name {
    display: block;
    font-size: 44px;
    font-size: 4.4rem;
    font-weight: bold;
    font-family: 'Kosugi Maru', sans-serif;
    color: #00acbd; }
    @media screen and (max-width: 769px) {
      .logo__name {
        font-size: 22px;
        font-size: 2.2rem; } }
  .logo__img {
    display: block; }
    .logo__img img {
      width: 440px; }
    @media screen and (max-width: 769px) {
      .logo__img {
        font-size: 22px;
        font-size: 2.2rem; }
        .logo__img img {
          width: 100%;
          vertical-align: middle; } }
    .logo__img.cat01 {
      text-align: center; }
      @media screen and (max-width: 769px) {
        .logo__img.cat01 {
          font-size: 22px;
          font-size: 2.2rem; }
          .logo__img.cat01 img {
            width: 85%; } }

body {
  position: relative;
  padding-top: 124px; }
  @media screen and (max-width: 769px) {
    body {
      padding-top: 65px; } }

body.home {
  padding-top: 0; }
  body.home .header {
    width: 100%;
    position: absolute;
    top: calc(100vh - 124px);
    left: 0;
    z-index: 999; }
  @media screen and (max-width: 769px) {
    body.home {
      padding-top: 65px; }
      body.home .header {
        position: fixed;
        top: 0;
        z-index: 9999; } }

body.navi-fixed .header {
  position: fixed;
  left: 0;
  top: 0; }

.header {
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  height: 124px;
  z-index: 9999; }
  @media screen and (max-width: 769px) {
    .header {
      display: block;
      height: auto;
      z-index: 9999; }
      .header .sp-menu {
        display: flex;
        width: 100%;
        height: 65px;
        background-color: #00acbd; }
        .header .sp-menu .btns {
          display: flex;
          width: calc(100% - 65px);
          border-right: 2px solid #fff; }
        .header .sp-menu a {
          display: block;
          color: #fff;
          padding-top: 10px;
          text-align: center;
          line-height: 1;
          font-size: 13px;
          font-size: 1.3rem; }
          .header .sp-menu a.home {
            width: 24.42%; }
            .header .sp-menu a.home img {
              width: 28px; }
          .header .sp-menu a.tel1, .header .sp-menu a.tel2 {
            width: 37.78%;
            border-left: 1px solid #fff; }
            .header .sp-menu a.tel1 img, .header .sp-menu a.tel2 img {
              width: 23px; }
          .header .sp-menu a .icon {
            height: 28px;
            margin-bottom: 6px; }
      .header .sp-menu-btn {
        display: block;
        background-color: #00acbd;
        width: 64px;
        height: 65px;
        line-height: 1;
        text-align: center;
        padding: 10px 12px 6px;
        box-sizing: border-box; }
        .header .sp-menu-btn .bars {
          display: flex;
          align-items: center;
          width: 40px;
          height: 28px;
          margin-bottom: 6px;
          position: relative; }
          .header .sp-menu-btn .bars:before, .header .sp-menu-btn .bars:after,
          .header .sp-menu-btn .bars div {
            background-color: #fff;
            width: 100%;
            height: 4px;
            border-radius: 4px; }
          .header .sp-menu-btn .bars:before, .header .sp-menu-btn .bars:after {
            content: "";
            position: absolute;
            left: 0; }
          .header .sp-menu-btn .bars:before {
            top: 0; }
          .header .sp-menu-btn .bars:after {
            bottom: 0; } }
  .header__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1168px;
    margin: 0 auto;
    padding: 16px 0; }
    @media screen and (max-width: 769px) {
      .header__inner {
        flex-direction: column;
        margin: 0;
        padding: 0; }
        .header__inner .logo {
          display: none; } }
  .header .header-right {
    width: 706px; }
    @media screen and (max-width: 769px) {
      .header .header-right {
        width: 100%; }
        .header .header-right .tel {
          display: none; } }
  @media screen and (min-width: 770px) {
    .header .catch {
      text-align: right;
      font-size: 14px;
      font-size: 1.4rem;
      letter-spacing: 0.16em; } }
  @media screen and (max-width: 769px) {
    .header .catch {
      margin-bottom: 8px;
      text-align: center;
      font-size: 12px;
      font-size: 1.2rem; } }
  .header .address {
    font-size: 0; }
    @media screen and (min-width: 770px) {
      .header .address {
        text-align: right; } }
    @media screen and (max-width: 769px) {
      .header .address {
        text-align: center; } }
    .header .address dt,
    .header .address dd {
      display: inline-block;
      font-size: 14px;
      font-size: 1.4rem;
      letter-spacing: 0.16em; }
      @media screen and (max-width: 769px) {
        .header .address dt,
        .header .address dd {
          font-size: 12px;
          font-size: 1.2rem; } }
    .header .address dt {
      margin-right: .5em; }
  .header .tel {
    position: relative;
    display: flex;
    line-height: 1.4; }
    @media screen and (max-width: 769px) {
      .header .tel {
        display: block;
        padding-left: 0;
        text-align: center;
        justify-content: space-between; }
        .header .tel::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          display: block;
          width: 24px;
          height: 26px;
          background: url(../img/common/icon_tel.png) no-repeat;
          background-size: contain; } }
    @media screen and (max-width: 769px) and (max-width: 769px) {
      .header .tel::before {
        width: 30px;
        height: 30px;
        left: 2vw; } }
    @media screen and (max-width: 769px) and (max-width: 374px) {
      .header .tel::before {
        left: 2vw; } }
    @media screen and (min-width: 770px) {
      .header .tel {
        text-align: right; } }
    .header .tel dl {
      width: 50%;
      padding: 0 18px;
      margin-right: 1em;
      text-align: left; }
      .header .tel dl:first-child dt {
        background: #84bf40; }
      .header .tel dl:last-child {
        margin-right: 0; }
        .header .tel dl:last-child dt {
          background: #00abb7; }
      @media screen and (max-width: 769px) {
        .header .tel dl {
          display: block;
          width: calc(100% - 8vw);
          padding-left: 8vw; } }
    .header .tel dt,
    .header .tel dd {
      display: inline-block; }
      @media screen and (max-width: 769px) {
        .header .tel dt,
        .header .tel dd {
          font-size: 20px;
          font-size: 2rem; } }
    .header .tel dt {
      display: inline-block;
      margin-right: 5px;
      margin-left: 4px;
      padding: 5px 8px 6px;
      font-weight: bold;
      line-height: 1;
      color: #fff;
      border-radius: 2px;
      font-size: 22px;
      font-size: 2.2rem; }
      @media screen and (max-width: 769px) {
        .header .tel dt {
          width: 5em;
          text-align: left;
          font-size: 18px;
          font-size: 1.8rem; } }
    .header .tel dd {
      font-size: 26px;
      font-size: 2.6rem; }
  .header .small {
    text-align: right; }
    @media screen and (max-width: 769px) {
      .header .small {
        padding-right: 4em; } }

@media screen and (max-width: 769px) {
  .navi-area {
    position: absolute;
    width: 100%;
    top: 65px;
    left: 0;
    background-color: #fff;
    display: none; } }

.navi {
  width: 100%;
  margin: 17px auto 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  font-size: 20px;
  font-size: 2rem;
  line-height: 1;
  letter-spacing: 0; }
  @media screen and (max-width: 769px) {
    .navi {
      flex-wrap: wrap;
      margin: 0;
      font-size: 13px;
      font-size: 1.3rem; } }
  .navi li {
    width: 16.66%;
    border-left: solid 1px #b3b3b4;
    box-sizing: border-box; }
    .navi li:first-child {
      border-left: none; }
    .navi li.current {
      background: #00acbd; }
      .navi li.current a {
        color: #fff; }
    @media screen and (max-width: 769px) {
      .navi li {
        width: 100%;
        height: 40px;
        box-sizing: border-box;
        border-left: none;
        border-bottom: solid 1px #c5c5c5; }
        .navi li:nth-child(odd) {
          border-left: none; } }
    @media screen and (max-width: 769px) {
      .navi li:last-child {
        width: 100%;
        border: none; } }
  .navi a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 3px 0 5px; }
    @media screen and (max-width: 769px) {
      .navi a {
        height: 40px;
        box-sizing: border-box; } }

.kv {
  padding-top: 27%; }

.bottom-link {
  margin-bottom: 50px;
  padding: 30px 0;
  background: rgba(0, 172, 189, 0.1); }
  @media screen and (max-width: 769px) {
    .bottom-link {
      margin-bottom: 25px; } }
  .bottom-link__inner {
    max-width: 880px;
    margin: 0 auto;
    display: flex;
    justify-content: space-around; }
    @media screen and (max-width: 769px) {
      .bottom-link__inner {
        margin: 0 5%;
        justify-content: space-between; } }
  @media screen and (max-width: 769px) {
    .bottom-link li {
      width: 32%; } }
  .bottom-link__item {
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    letter-spacing: 0.08em; }
    @media screen and (min-width: 770px) {
      .bottom-link__item {
        width: 250px;
        height: 230px;
        font-size: 22px;
        font-size: 2.2rem; } }
    @media screen and (max-width: 769px) {
      .bottom-link__item {
        width: 100%;
        height: 100%;
        padding: 10px 5px;
        box-sizing: border-box;
        font-size: 12px;
        font-size: 1.2rem; } }
    .bottom-link__item img {
      width: 140px;
      margin-bottom: 30px; }
      @media screen and (max-width: 769px) {
        .bottom-link__item img {
          width: 80px;
          margin-bottom: 15px; } }

.btn-pagetop {
  position: relative;
  height: 0px;
  z-index: 8888; }
  .btn-pagetop.is-fixed a {
    position: fixed;
    right: 30px;
    bottom: 0; }
  .btn-pagetop a {
    display: block;
    position: absolute;
    right: 30px;
    bottom: 0;
    margin-bottom: 20px;
    transform: translate3d(0, 0, 0); }
    .btn-pagetop a:hover img {
      opacity: 0.8; }
    .btn-pagetop a img {
      transition: all 0.2s;
      width: 80px; }
  @media screen and (max-width: 769px) {
    .btn-pagetop.is-fixed a {
      right: 10px; }
    .btn-pagetop a {
      right: 10px; }
      .btn-pagetop a img {
        width: 48px; } }

.page-top {
  position: relative;
  display: block;
  padding: 40px 0 20px;
  width: 100%;
  background: #f0f0f0;
  text-align: center;
  font-size: 18px;
  font-size: 1.8rem;
  letter-spacing: 0.08em; }
  @media screen and (max-width: 769px) {
    .page-top {
      padding: 30px 0 5px;
      font-size: 14px;
      font-size: 1.4rem; } }
  .page-top::before {
    position: absolute;
    top: 20px;
    left: 0;
    right: 0;
    margin: auto;
    content: "";
    display: block;
    width: 28px;
    height: 16px;
    background: url(../img/common/icon_up.png) no-repeat center;
    background-size: contain; }
    @media screen and (max-width: 769px) {
      .page-top::before {
        top: 10px; } }

.footer__inner {
  max-width: 1024px;
  margin: 0 auto;
  padding: 40px 0 50px; }
  @media screen and (max-width: 769px) {
    .footer__inner {
      margin: 0 5%; } }

.footer__card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px #c3c3c3;
  padding-bottom: 30px;
  margin-bottom: 20px; }
  @media screen and (max-width: 769px) {
    .footer__card {
      padding-bottom: 15px;
      margin-bottom: 15px;
      flex-direction: column; } }
  .footer__card .logo img {
    width: 400px; }
    @media screen and (max-width: 769px) {
      .footer__card .logo img {
        width: 100%; } }

.footer .address {
  text-align: right;
  font-size: 0; }
  @media screen and (max-width: 769px) {
    .footer .address {
      text-align: left; } }
  .footer .address dt,
  .footer .address dd {
    display: inline-block;
    font-size: 14px;
    font-size: 1.4rem;
    letter-spacing: 0.16em; }
    @media screen and (max-width: 769px) {
      .footer .address dt,
      .footer .address dd {
        font-size: 12px;
        font-size: 1.2rem; } }
  .footer .address dt {
    margin-right: .5em; }

.footer .tel {
  position: relative;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  line-height: 1.4; }
  @media screen and (max-width: 769px) {
    .footer .tel {
      text-align: left;
      justify-content: flex-start; } }
  .footer .tel dl {
    margin-right: 1em;
    letter-spacing: 0.16em; }
    .footer .tel dl:last-child {
      margin-right: 0; }
  .footer .tel dt {
    margin-right: 0.5em; }
  .footer .tel dt,
  .footer .tel dd {
    display: inline-block;
    font-size: 14px;
    font-size: 1.4rem; }
    @media screen and (max-width: 769px) {
      .footer .tel dt,
      .footer .tel dd {
        font-size: 12px;
        font-size: 1.2rem; } }

.footer__link-area {
  margin-bottom: 40px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between; }
  @media screen and (max-width: 769px) {
    .footer__link-area {
      margin-bottom: 20px;
      justify-content: space-around; } }

.footer__navi {
  display: flex;
  flex-wrap: wrap;
  width: 60%; }
  @media screen and (max-width: 769px) {
    .footer__navi {
      width: 100%; } }
  .footer__navi li {
    box-sizing: border-box;
    min-width: 180px;
    padding-left: 10px;
    margin-bottom: 10px;
    font-size: 14px;
    font-size: 1.4rem;
    color: #00acbd; }
    @media screen and (max-width: 769px) {
      .footer__navi li {
        padding-left: 0px;
        min-width: inherit;
        width: 100%; } }
    .footer__navi li a {
      color: #00acbd; }

.footer .contact {
  background: #00acbd;
  width: 100px;
  padding: 8px 0 0 8px;
  border: solid 2px #00acbd;
  box-shadow: 1px 2px 1px rgba(0, 172, 189, 0.5); }
  .footer .contact img {
    display: block;
    width: 100%; }

.footer .copyright {
  text-align: center;
  font-size: 13px;
  font-size: 1.3rem; }
  @media screen and (max-width: 769px) {
    .footer .copyright {
      font-size: 11px;
      font-size: 1.1rem; } }

.page-index .kv {
  background: url(../img/top/key_visual.png) no-repeat center center;
  background-size: cover; }

.page-index .content {
  counter-reset: number; }
  .page-index .content__ttl {
    margin-bottom: 50px;
    padding: 30px 0 15px;
    text-align: center;
    border-bottom: solid 1px #ccc;
    font-size: 25px;
    font-size: 2.5rem;
    font-weight: bold;
    letter-spacing: 0.12em; }
    @media screen and (max-width: 769px) {
      .page-index .content__ttl {
        margin-bottom: 25px;
        padding: 15px 0 8px;
        font-size: 20px;
        font-size: 2rem; } }

@media screen and (min-width: 770px) {
  .page-index .wrap {
    width: 880px;
    margin: 0 auto; } }

@media screen and (max-width: 769px) {
  .page-index .wrap {
    width: auto;
    margin: 0 5%; } }

.page-index .lead {
  margin-bottom: 100px;
  font-weight: bold; }
  @media screen and (max-width: 769px) {
    .page-index .lead {
      margin-bottom: 50px; } }
  .page-index .lead__ttl {
    margin-bottom: 10px;
    text-align: center;
    font-size: 22px;
    font-size: 2.2rem;
    letter-spacing: 0.12em; }
    @media screen and (max-width: 769px) {
      .page-index .lead__ttl {
        font-size: 18px;
        font-size: 1.8rem; } }
  .page-index .lead__text {
    word-break: break-all; }
    @media screen and (min-width: 770px) {
      .page-index .lead__text {
        font-size: 18px;
        font-size: 1.8rem;
        width: 64%;
        margin: 0 auto; } }
    @media screen and (max-width: 769px) {
      .page-index .lead__text {
        font-size: 14px;
        font-size: 1.4rem; } }

.page-index .tel-info {
  width: 740px;
  box-sizing: border-box;
  margin-right: auto;
  margin-left: auto;
  padding: 40px 70px;
  border-radius: 7px;
  line-height: 1.4;
  background: rgba(0, 172, 189, 0.1);
  font-size: 50px;
  font-size: 5rem; }
  @media screen and (max-width: 769px) {
    .page-index .tel-info {
      width: auto;
      padding: 20px 5%;
      font-size: 30px;
      font-size: 3rem; } }
  .page-index .tel-info__item {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: solid 1px #00acbd; }
    .page-index .tel-info__item:last-child {
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: none; }
    .page-index .tel-info__item .note {
      font-size: 17px;
      font-size: 1.7rem; }
      @media screen and (max-width: 769px) {
        .page-index .tel-info__item .note {
          font-size: 12px;
          font-size: 1.2rem; } }
  .page-index .tel-info .item--tel::before {
    content: "";
    display: block;
    width: 56px;
    height: 56px;
    margin-right: 30px;
    background: url(../img/visiting_care/icon_tel.png) no-repeat;
    background-size: contain; }
    @media screen and (max-width: 769px) {
      .page-index .tel-info .item--tel::before {
        width: 28px;
        height: 28px;
        margin-right: 5%; } }
  .page-index .tel-info .item--tel a {
    display: block; }
  .page-index .tel-info .item--fax::before {
    content: "";
    display: block;
    width: 56px;
    height: 81px;
    margin-right: 30px;
    background: url(../img/visiting_care/icon_fax.png) no-repeat;
    background-size: contain; }
    @media screen and (max-width: 769px) {
      .page-index .tel-info .item--fax::before {
        width: 28px;
        height: 40px;
        margin-right: 5%; } }
  .page-index .tel-info .item--fax a {
    display: block; }

.page-index .block {
  margin-bottom: 120px; }
  @media screen and (max-width: 769px) {
    .page-index .block {
      margin-bottom: 60px; } }
  .page-index .block__ttl {
    margin-bottom: 50px;
    font-size: 22px;
    font-size: 2.2rem;
    letter-spacing: 0.12em;
    text-align: center;
    font-weight: bold; }
    @media screen and (max-width: 769px) {
      .page-index .block__ttl {
        margin-bottom: 25px;
        font-size: 18px;
        font-size: 1.8rem; } }
    .page-index .block__ttl--2 {
      margin-bottom: 30px; }
      @media screen and (max-width: 769px) {
        .page-index .block__ttl--2 {
          margin-bottom: 15px; } }
    .page-index .block__ttl.num::before {
      display: block;
      counter-increment: number;
      content: counter(number, decimal-leading-zero);
      font-size: 45px;
      font-size: 4.5rem;
      font-family: lato, sans-serif;
      line-height: 1;
      color: #00acbd; }
      @media screen and (max-width: 769px) {
        .page-index .block__ttl.num::before {
          font-size: 28px;
          font-size: 2.8rem; } }
    .page-index .block__ttl span {
      position: relative;
      display: inline-block;
      padding: 0 120px; }
      @media screen and (max-width: 769px) {
        .page-index .block__ttl span {
          padding: 0 30px; } }
      .page-index .block__ttl span::before, .page-index .block__ttl span::after {
        content: '';
        position: absolute;
        top: 50%;
        display: inline-block;
        width: 100px;
        height: 1px;
        background-color: #333; }
        @media screen and (max-width: 769px) {
          .page-index .block__ttl span::before, .page-index .block__ttl span::after {
            width: 20px; } }
      .page-index .block__ttl span::before {
        left: 0; }
      .page-index .block__ttl span:after {
        right: 0; }
  .page-index .block__list {
    display: flex;
    justify-content: space-between; }
    @media screen and (max-width: 769px) {
      .page-index .block__list {
        flex-direction: column; } }
    .page-index .block__list .list-wrap {
      width: 47%; }
      @media screen and (max-width: 769px) {
        .page-index .block__list .list-wrap {
          width: auto;
          margin: 0 auto; } }
    .page-index .block__list .list {
      margin-bottom: 30px; }
      @media screen and (max-width: 769px) {
        .page-index .block__list .list {
          margin-bottom: 15px; } }
    .page-index .block__list .case {
      display: flex;
      align-items: center;
      font-size: 27px;
      font-size: 2.7rem; }
      @media screen and (max-width: 769px) {
        .page-index .block__list .case {
          font-size: 16px;
          font-size: 1.6rem; } }
      .page-index .block__list .case__img {
        width: 110px;
        margin-right: 16px; }
        @media screen and (max-width: 769px) {
          .page-index .block__list .case__img {
            width: 60px; } }
        .page-index .block__list .case__img img {
          display: block;
          width: 100%; }
    .page-index .block__list .ex {
      margin: 24px 0 60px;
      font-size: 24px;
      font-size: 2.4rem; }
      @media screen and (max-width: 769px) {
        .page-index .block__list .ex {
          margin: 12px 0;
          font-size: 14px;
          font-size: 1.4rem; } }
      .page-index .block__list .ex::before {
        content: "例：";
        color: #00acbd; }
      .page-index .block__list .ex li {
        display: flex;
        align-items: center;
        line-height: 2; }
        .page-index .block__list .ex li::before {
          content: "■";
          color: #00acbd;
          font-size: 12px;
          font-size: 1.2rem;
          margin-right: 1em; }
    .page-index .block__list .note {
      font-size: 18px;
      font-size: 1.8rem;
      color: #00acbd;
      text-align: center; }
      @media screen and (max-width: 769px) {
        .page-index .block__list .note {
          margin-bottom: 10px;
          font-size: 12px;
          font-size: 1.2rem; } }
  .page-index .block__img {
    margin-bottom: 100px; }
    @media screen and (max-width: 769px) {
      .page-index .block__img {
        margin-bottom: 50px; } }
    .page-index .block__img:last-child {
      margin-bottom: 0; }
    .page-index .block__img img {
      display: block;
      width: 100%; }
  .page-index .block__lead {
    margin-bottom: 15px;
    font-size: 18px;
    font-size: 1.8rem;
    text-align: center; }
    @media screen and (max-width: 769px) {
      .page-index .block__lead {
        text-align: left;
        font-size: 14px;
        font-size: 1.4rem; } }
  .page-index .block__table {
    width: 100%; }
    .page-index .block__table tr:first-child th, .page-index .block__table tr:first-child td {
      border-bottom: none; }
    .page-index .block__table th, .page-index .block__table td {
      padding: 10px 20px;
      border-right: solid 1px #fff;
      border-bottom: solid 1px #fff;
      text-align: center;
      line-height: 1.4; }
      @media screen and (max-width: 769px) {
        .page-index .block__table th, .page-index .block__table td {
          padding: 10px; } }
    .page-index .block__table th {
      font-size: 19px;
      font-size: 1.9rem; }
      @media screen and (max-width: 769px) {
        .page-index .block__table th {
          font-size: 14px;
          font-size: 1.4rem; } }
      .page-index .block__table th.bg-light-main {
        color: #00acbd; }
    .page-index .block__table td {
      font-size: 18px;
      font-size: 1.8rem; }
      @media screen and (max-width: 769px) {
        .page-index .block__table td {
          font-size: 12px;
          font-size: 1.2rem; } }
    .page-index .block__table .bg-main {
      background: #00acbd;
      color: #fff; }
    .page-index .block__table .bg-light-main {
      background: rgba(0, 172, 189, 0.1); }
    .page-index .block__table .bg-sub {
      background: #4c4948;
      color: #fff; }
    .page-index .block__table .bg-light-sub {
      background: rgba(76, 73, 72, 0.1); }
    .page-index .block__table .align-l {
      text-align: left; }

.content {
  counter-reset: number; }
  .content__navi {
    display: flex;
    justify-content: space-between;
    margin-bottom: 70px; }
    .content__navi a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 236px;
      height: 80px;
      font-size: 19px;
      font-size: 1.9rem;
      letter-spacing: 0.11em;
      border: solid 1px #00acbd;
      border-radius: 4px;
      position: relative; }
      .content__navi a:before {
        content: "";
        width: 35px;
        height: 32px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        position: absolute;
        left: 10px;
        top: 50%;
        margin-top: -16px; }
      .content__navi a span {
        display: inline-block;
        position: relative;
        z-index: 1;
        padding-bottom: 2px; }
    @media screen and (max-width: 769px) {
      .content__navi {
        flex-wrap: wrap; }
        .content__navi.wrap {
          margin: 0 10px 20px; }
        .content__navi a {
          width: 48.59%;
          height: 60px;
          margin-bottom: 10px;
          font-size: 14px;
          font-size: 1.4rem;
          letter-spacing: 0.06em; } }
  .content .block__ttl {
    color: #fff;
    margin-bottom: 50px;
    font-size: 30px;
    font-size: 3rem;
    letter-spacing: 0.01em;
    text-align: center;
    overflow: hidden; }
    .content .block__ttl.blue .in::before, .content .block__ttl.blue .in::after {
      background-color: #00acbd; }
    .content .block__ttl.green .in::before, .content .block__ttl.green .in::after {
      background-color: #008e5c; }
    .content .block__ttl.light-green .in::before, .content .block__ttl.light-green .in::after {
      background-color: #88c23f; }
    @media screen and (max-width: 769px) {
      .content .block__ttl {
        margin-bottom: 26px;
        font-size: 21px;
        font-size: 2.1rem; } }
    .content .block__ttl .in {
      position: relative;
      display: inline-block;
      vertical-align: top;
      line-height: 1.5; }
      .content .block__ttl .in::before, .content .block__ttl .in::after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0%;
        width: 100%;
        height: 110%;
        border-radius: 4px; }
      .content .block__ttl .in::before {
        left: -15px;
        transform: skewX(25deg); }
      .content .block__ttl .in:after {
        right: -15px;
        transform: skewX(-25deg); }
      .content .block__ttl .in span {
        display: inline-block;
        width: 280px;
        position: relative;
        z-index: 1; }
      @media screen and (max-width: 769px) {
        .content .block__ttl .in {
          padding-bottom: 2px; }
          .content .block__ttl .in::before, .content .block__ttl .in::after {
            border-radius: 2px; }
          .content .block__ttl .in span {
            width: 200px; } }
  .content .block__list {
    list-style: none;
    font-size: 1.6rem; }

body.home .content {
  padding-top: 124px; }
  @media screen and (max-width: 769px) {
    body.home .content {
      padding-top: 0; } }

.page-outpatient .kv {
  width: 100%;
  height: calc(100vh - 124px);
  padding-top: 0; }
  @media screen and (max-width: 769px) {
    .page-outpatient .kv {
      height: 200px; } }
  .page-outpatient .kv ul {
    width: 100%; }
    .page-outpatient .kv ul li.slide {
      width: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover; }
      .page-outpatient .kv ul li.slide a {
        display: block;
        width: 100%;
        height: calc(100vh - 124px);
        position: relative; }
        .page-outpatient .kv ul li.slide a .title {
          position: absolute;
          left: 0; }
      .page-outpatient .kv ul li.slide.slide1 {
        background-image: url(../img/top/key_visual01.png); }
        .page-outpatient .kv ul li.slide.slide1 .title {
          width: 62.97%;
          top: 119px; }
      .page-outpatient .kv ul li.slide.slide2 {
        background-image: url(../img/top/key_visual02.png); }
        .page-outpatient .kv ul li.slide.slide2 .title {
          width: 52.38%;
          top: 119px; }
      .page-outpatient .kv ul li.slide.slide3 {
        background-image: url(../img/top/key_visual03.png); }
        .page-outpatient .kv ul li.slide.slide3 .title {
          width: 55.95%;
          bottom: 140px; }
      .page-outpatient .kv ul li.slide.slide4 {
        background-image: url(../img/top/key_visual04.png); }
        .page-outpatient .kv ul li.slide.slide4 .title {
          width: 53.69%;
          top: 120px; }
    @media screen and (max-width: 769px) {
      .page-outpatient .kv ul li.slide a {
        height: 200px; }
      .page-outpatient .kv ul li.slide.slide1 {
        background-image: url(../img/top/key_visual01_sp.png); }
        .page-outpatient .kv ul li.slide.slide1 .title {
          width: 73.33%;
          top: 19px; }
      .page-outpatient .kv ul li.slide.slide2 {
        background-image: url(../img/top/key_visual02_sp.png); }
        .page-outpatient .kv ul li.slide.slide2 .title {
          width: 61.86%;
          top: 19px; }
      .page-outpatient .kv ul li.slide.slide3 {
        background-image: url(../img/top/key_visual03_sp.png); }
        .page-outpatient .kv ul li.slide.slide3 .title {
          width: 61.6%;
          bottom: 27px; }
      .page-outpatient .kv ul li.slide.slide4 {
        background-image: url(../img/top/key_visual04_sp.png); }
        .page-outpatient .kv ul li.slide.slide4 .title {
          width: 62.93%;
          top: 19px; } }
  .page-outpatient .kv .slick-arrow.slick-prev, .page-outpatient .kv .slick-arrow.slick-next {
    width: 60px;
    height: 60px;
    border-top: solid 4px #fff;
    position: absolute;
    top: 50%;
    z-index: 1; }
    .page-outpatient .kv .slick-arrow.slick-prev:before, .page-outpatient .kv .slick-arrow.slick-next:before {
      display: none; }
  .page-outpatient .kv .slick-arrow.slick-prev {
    transform: rotate(-45deg);
    border-left: solid 4px #fff;
    left: 50px; }
  .page-outpatient .kv .slick-arrow.slick-next {
    transform: rotate(45deg);
    border-right: solid 4px #fff;
    right: 50px; }
  @media screen and (max-width: 769px) {
    .page-outpatient .kv .slick-arrow.slick-prev, .page-outpatient .kv .slick-arrow.slick-next {
      width: 14px;
      height: 14px;
      border-top: solid 2px #fff;
      margin-top: -4px; }
    .page-outpatient .kv .slick-arrow.slick-prev {
      border-left: solid 2px #fff;
      left: 10px; }
    .page-outpatient .kv .slick-arrow.slick-next {
      border-right: solid 2px #fff;
      right: 10px; } }

@media screen and (min-width: 770px) {
  .page-outpatient .wrap {
    width: 980px;
    margin-left: auto;
    margin-right: auto; } }

@media screen and (max-width: 769px) {
  .page-outpatient .wrap {
    width: auto;
    margin-left: 5%;
    margin-right: 5%; } }

.page-outpatient .content__ttl {
  padding: 45px 0 30px;
  text-align: center;
  font-size: 36px;
  font-size: 3.6rem;
  font-weight: normal;
  letter-spacing: 0.12em; }
  @media screen and (max-width: 769px) {
    .page-outpatient .content__ttl {
      padding: 15px 0;
      font-size: 21px;
      font-size: 2.1rem; } }

.page-outpatient .content__navi a:nth-of-type(1):before {
  background-image: url(../img/top/icon_06.png); }

.page-outpatient .content__navi a:nth-of-type(2) {
  letter-spacing: 0.07em; }
  .page-outpatient .content__navi a:nth-of-type(2):before {
    background-image: url(../img/top/icon_07.png); }
  .page-outpatient .content__navi a:nth-of-type(2) span {
    padding-left: 40px; }

.page-outpatient .content__navi a:nth-of-type(3):before {
  background-image: url(../img/top/icon_08.png); }

.page-outpatient .content__navi a:nth-of-type(3) span {
  padding-left: 20px; }

.page-outpatient .content__navi a:nth-of-type(4):before {
  background-image: url(../img/top/icon_09.png); }

@media screen and (max-width: 769px) {
  .page-outpatient .content__navi a:nth-of-type(1):before {
    width: 23px; }
  .page-outpatient .content__navi a:nth-of-type(2) {
    letter-spacing: 0.07em; }
    .page-outpatient .content__navi a:nth-of-type(2):before {
      width: 22px; }
    .page-outpatient .content__navi a:nth-of-type(2) span {
      padding-left: 30px; }
  .page-outpatient .content__navi a:nth-of-type(3):before {
    width: 22px; }
  .page-outpatient .content__navi a:nth-of-type(3) span {
    padding-left: 10px; }
  .page-outpatient .content__navi a:nth-of-type(4):before {
    width: 24px; } }

.page-outpatient .outpatient-block {
  background: rgba(0, 172, 189, 0.1);
  padding-bottom: 40px; }
  .page-outpatient .outpatient-block .wrap {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .page-outpatient .outpatient-block .photo {
    margin-right: 40px; }
  .page-outpatient .outpatient-block .information {
    width: 504px;
    letter-spacing: 0.11em; }
    .page-outpatient .outpatient-block .information .title {
      color: #00acbd;
      font-size: 30px;
      font-size: 3rem; }
    .page-outpatient .outpatient-block .information .txt {
      margin-top: 20px;
      font-size: 17.5px;
      font-size: 1.75rem;
      line-height: 31.5px; }
    .page-outpatient .outpatient-block .information .btn-more {
      margin-top: 20px;
      text-align: right;
      font-size: 14px;
      font-size: 1.4rem; }
  @media screen and (max-width: 769px) {
    .page-outpatient .outpatient-block {
      padding-bottom: 20px; }
      .page-outpatient .outpatient-block .wrap {
        display: block; }
      .page-outpatient .outpatient-block .photo {
        margin-right: 0;
        text-align: center; }
        .page-outpatient .outpatient-block .photo img {
          width: 100%;
          max-width: 265px; }
      .page-outpatient .outpatient-block .information {
        width: 100%;
        margin-top: 10px; }
        .page-outpatient .outpatient-block .information .title {
          text-align: center;
          font-size: 21px;
          font-size: 2.1rem; }
        .page-outpatient .outpatient-block .information .txt {
          margin-top: 10px;
          font-size: 14px;
          font-size: 1.4rem;
          line-height: 27.5px; }
        .page-outpatient .outpatient-block .information .btn-more {
          margin-top: 5px; } }

.page-outpatient .subjects {
  width: 495px;
  margin-bottom: 16px; }
  @media screen and (max-width: 769px) {
    .page-outpatient .subjects {
      width: 100%;
      margin-bottom: 0;
      margin-top: 20px; } }
  .page-outpatient .subjects dt {
    margin-bottom: 10px;
    padding-bottom: 10px;
    font-size: 20px;
    font-size: 2rem;
    border-bottom: dotted 2px #c5c5c5;
    letter-spacing: 0.12em; }
    @media screen and (max-width: 769px) {
      .page-outpatient .subjects dt {
        font-size: 18px;
        font-size: 1.8rem; } }
    .page-outpatient .subjects dt.icon_course {
      display: flex;
      align-items: center; }
      .page-outpatient .subjects dt.icon_course::before {
        content: "";
        display: block;
        width: 42px;
        height: 35px;
        margin: 0 10px 0 0;
        background: url(../img/common/icon_course.png) no-repeat center;
        background-size: contain; }
    .page-outpatient .subjects dt.icon_physical-therapy {
      display: flex;
      align-items: center; }
      .page-outpatient .subjects dt.icon_physical-therapy::before {
        content: "";
        display: block;
        width: 42px;
        height: 35px;
        margin: 0 10px 0 0;
        background: url(../img/common/icon_physical-therapy.png) no-repeat center;
        background-size: contain; }
    .page-outpatient .subjects dt.icon_closed {
      display: flex;
      align-items: center; }
      .page-outpatient .subjects dt.icon_closed::before {
        content: "";
        display: block;
        width: 42px;
        height: 35px;
        margin: 0 10px 0 0;
        background: url(../img/common/icon_closed.png) no-repeat center;
        background-size: contain; }
  .page-outpatient .subjects dd {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.5;
    letter-spacing: 0.14em; }
    @media screen and (max-width: 769px) {
      .page-outpatient .subjects dd {
        font-size: 14px;
        font-size: 1.4rem; } }
    .page-outpatient .subjects dd a {
      color: #00acbd; }

.page-outpatient .schedule-block {
  background: rgba(0, 172, 189, 0.1);
  padding-bottom: 120px; }
  @media screen and (max-width: 769px) {
    .page-outpatient .schedule-block {
      padding-bottom: 30px; }
      .page-outpatient .schedule-block .wrap {
        margin: 0; } }
  .page-outpatient .schedule-block .outpatient {
    display: flex;
    justify-content: space-between; }
    @media screen and (min-width: 770px) {
      .page-outpatient .schedule-block .outpatient {
        display: flex;
        justify-content: space-between;
        width: 854px;
        margin: 35px auto 30px; }
        .page-outpatient .schedule-block .outpatient dl {
          width: 422px;
          padding: 25px 25px 10px;
          background: #fff;
          box-sizing: border-box; } }
    @media screen and (max-width: 769px) {
      .page-outpatient .schedule-block .outpatient {
        width: auto;
        margin: 0 5% 30px; } }
    @media screen and (max-width: 769px) {
      .page-outpatient .schedule-block .outpatient {
        display: block; } }
  .page-outpatient .schedule-block .table-wrap .schedule {
    width: 100%;
    font-size: 16px;
    font-size: 1.6rem;
    border: solid 1px #c5c5c5; }
    @media screen and (max-width: 769px) {
      .page-outpatient .schedule-block .table-wrap .schedule {
        width: 100%;
        font-size: 14px;
        font-size: 1.4rem; } }
    .page-outpatient .schedule-block .table-wrap .schedule .bg-main {
      background: #00acbd;
      color: #fff;
      padding: 20px 20px 22px;
      border-right: solid 1px #fff;
      border-bottom: none; }
      .page-outpatient .schedule-block .table-wrap .schedule .bg-main:last-child {
        border: none; }
    .page-outpatient .schedule-block .table-wrap .schedule th, .page-outpatient .schedule-block .table-wrap .schedule td {
      background: #fff;
      padding: 15px 0px 10px;
      text-align: center;
      border-bottom: solid 1px #c5c5c5;
      border-right: solid 1px #c5c5c5;
      line-height: 35px;
      vertical-align: top;
      letter-spacing: 0;
      font-size: 22px;
      font-size: 2.2rem; }
      .page-outpatient .schedule-block .table-wrap .schedule th.item, .page-outpatient .schedule-block .table-wrap .schedule td.item {
        padding-left: 20px;
        text-align: left;
        font-size: 22px;
        font-size: 2.2rem; }
        .page-outpatient .schedule-block .table-wrap .schedule th.item p, .page-outpatient .schedule-block .table-wrap .schedule td.item p {
          line-height: 35px;
          margin-bottom: 30px; }
        .page-outpatient .schedule-block .table-wrap .schedule th.item .gray, .page-outpatient .schedule-block .table-wrap .schedule td.item .gray {
          font-size: 22px;
          font-size: 2.2rem; }
      .page-outpatient .schedule-block .table-wrap .schedule th:last-child, .page-outpatient .schedule-block .table-wrap .schedule td:last-child {
        border-right: none; }
      .page-outpatient .schedule-block .table-wrap .schedule th .big, .page-outpatient .schedule-block .table-wrap .schedule td .big {
        font-size: 25px;
        font-size: 2.5rem; }
      .page-outpatient .schedule-block .table-wrap .schedule th .sml, .page-outpatient .schedule-block .table-wrap .schedule td .sml {
        font-size: 17px;
        font-size: 1.7rem; }
      .page-outpatient .schedule-block .table-wrap .schedule th .bold, .page-outpatient .schedule-block .table-wrap .schedule td .bold {
        font-weight: bold; }
      .page-outpatient .schedule-block .table-wrap .schedule th p, .page-outpatient .schedule-block .table-wrap .schedule td p {
        margin-bottom: 15px;
        line-height: 26px; }
    .page-outpatient .schedule-block .table-wrap .schedule td .blue {
      font-size: 17px;
      font-size: 1.7rem; }
      .page-outpatient .schedule-block .table-wrap .schedule td .blue span {
        font-size: 13.5px;
        font-size: 1.35rem; }
    @media screen and (max-width: 769px) {
      .page-outpatient .schedule-block .table-wrap .schedule td {
        font-size: 16px;
        font-size: 1.6rem; } }
    .page-outpatient .schedule-block .table-wrap .schedule span.align-l {
      display: inline-block;
      margin-top: 5px;
      padding: 0 5px;
      text-align: left; }
    .page-outpatient .schedule-block .table-wrap .schedule .blue {
      color: #00acbd; }
    .page-outpatient .schedule-block .table-wrap .schedule .gray {
      color: #666;
      font-size: 18px;
      font-size: 1.8rem; }
      .page-outpatient .schedule-block .table-wrap .schedule .gray.sml {
        font-size: 14px;
        font-size: 1.4rem; }
  @media screen and (max-width: 769px) {
    .page-outpatient .schedule-block .table-wrap {
      overflow: scroll;
      -webkit-overflow-scrolling: touch;
      overflow-scrolling: touch;
      margin: 0 5%; }
      .page-outpatient .schedule-block .table-wrap .schedule {
        width: 670px;
        font-size: 14px;
        font-size: 1.4rem;
        border: solid 1px #c5c5c5; }
        .page-outpatient .schedule-block .table-wrap .schedule .bg-main {
          padding: 12px 10px 14px; }
        .page-outpatient .schedule-block .table-wrap .schedule th, .page-outpatient .schedule-block .table-wrap .schedule td {
          background: #fff;
          padding: 15px 0px 10px;
          line-height: 22px;
          vertical-align: top;
          letter-spacing: 0;
          font-size: 14px;
          font-size: 1.4rem; }
          .page-outpatient .schedule-block .table-wrap .schedule th.item, .page-outpatient .schedule-block .table-wrap .schedule td.item {
            padding-left: 10px;
            text-align: left;
            font-size: 14px;
            font-size: 1.4rem; }
            .page-outpatient .schedule-block .table-wrap .schedule th.item p, .page-outpatient .schedule-block .table-wrap .schedule td.item p {
              line-height: 22px;
              margin-bottom: 15px; }
            .page-outpatient .schedule-block .table-wrap .schedule th.item .gray, .page-outpatient .schedule-block .table-wrap .schedule td.item .gray {
              font-size: 14px;
              font-size: 1.4rem; }
          .page-outpatient .schedule-block .table-wrap .schedule th:last-child, .page-outpatient .schedule-block .table-wrap .schedule td:last-child {
            border-right: none; }
          .page-outpatient .schedule-block .table-wrap .schedule th .big, .page-outpatient .schedule-block .table-wrap .schedule td .big {
            font-size: 16px;
            font-size: 1.6rem; }
          .page-outpatient .schedule-block .table-wrap .schedule th .sml, .page-outpatient .schedule-block .table-wrap .schedule td .sml {
            font-size: 13px;
            font-size: 1.3rem; }
          .page-outpatient .schedule-block .table-wrap .schedule th p, .page-outpatient .schedule-block .table-wrap .schedule td p {
            margin-bottom: 10px;
            line-height: 22px; }
        .page-outpatient .schedule-block .table-wrap .schedule td .blue {
          font-size: 11px;
          font-size: 1.1rem;
          line-height: 1.38; }
          .page-outpatient .schedule-block .table-wrap .schedule td .blue span {
            font-size: 10px;
            font-size: 1rem; }
        .page-outpatient .schedule-block .table-wrap .schedule span.align-l {
          display: inline-block;
          margin-top: 5px;
          padding: 0 5px;
          text-align: left; }
        .page-outpatient .schedule-block .table-wrap .schedule .blue {
          color: #00acbd; }
        .page-outpatient .schedule-block .table-wrap .schedule .gray {
          color: #666;
          font-size: 14px;
          font-size: 1.4rem; }
          .page-outpatient .schedule-block .table-wrap .schedule .gray.sml {
            font-size: 10px;
            font-size: 1rem; } }
  .page-outpatient .schedule-block .notes {
    margin-top: 20px;
    font-size: 20px;
    font-size: 2rem;
    overflow: hidden;
    line-height: 35.5px; }
    .page-outpatient .schedule-block .notes dt {
      float: left;
      color: #00acbd;
      margin-right: 5px; }
    .page-outpatient .schedule-block .notes dd {
      overflow: hidden; }
    @media screen and (max-width: 769px) {
      .page-outpatient .schedule-block .notes {
        padding: 0 5%;
        font-size: 14px;
        font-size: 1.4rem;
        line-height: 1.5; } }

.page-outpatient .news-block {
  margin-bottom: 90px; }
  @media screen and (max-width: 769px) {
    .page-outpatient .news-block {
      margin-bottom: 20px; } }

.page-outpatient .news {
  display: flex;
  align-items: center;
  margin-bottom: 10px; }
  @media screen and (max-width: 769px) {
    .page-outpatient .news {
      display: block;
      align-items: flex-start;
      overflow: hidden; } }
  .page-outpatient .news__date {
    margin-right: 2em;
    font-size: 13px;
    font-size: 1.3rem; }
    @media screen and (max-width: 769px) {
      .page-outpatient .news__date {
        float: left;
        margin-right: 1.5em;
        width: 90px; } }
  .page-outpatient .news__text {
    font-size: 16px;
    font-size: 1.6rem; }
    @media screen and (max-width: 769px) {
      .page-outpatient .news__text {
        overflow: hidden;
        word-break: break-all;
        font-size: 14px;
        font-size: 1.4rem;
        text-align: left; } }

.page-outpatient .news-more {
  text-align: right;
  font-size: 14px;
  font-size: 1.4rem; }

.page-outpatient a.btn-text {
  position: relative;
  display: inline-block;
  padding: 0 12px 0 0;
  vertical-align: middle;
  text-decoration: none; }
  .page-outpatient a.btn-text::after {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 2px;
    margin: auto;
    vertical-align: middle;
    content: "";
    width: 6px;
    height: 6px;
    border-top: 2px solid #00acbd;
    border-right: 2px solid #00acbd;
    transform: rotate(45deg); }

.page-outpatient .btn-block {
  text-align: center; }
  .page-outpatient .btn-block a.btn-frame {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 340px;
    height: 94px;
    margin-left: auto;
    margin-right: auto;
    font-size: 30px;
    font-size: 3rem;
    letter-spacing: 0.11em;
    border: solid 2px #00acbd;
    border-radius: 6px;
    position: relative; }
    .page-outpatient .btn-block a.btn-frame span {
      display: inline-block;
      position: relative; }
      .page-outpatient .btn-block a.btn-frame span:before {
        content: "";
        border-top: 12px solid transparent;
        border-left: 16px solid #00acbd;
        border-bottom: 12px solid transparent;
        position: absolute;
        right: -24px;
        top: 50%;
        margin-top: -10px; }
  @media screen and (max-width: 769px) {
    .page-outpatient .btn-block a.btn-frame {
      font-size: 14px;
      font-size: 1.4rem;
      width: 170px;
      height: 47px; }
      .page-outpatient .btn-block a.btn-frame span:before {
        content: "";
        border-top: 7px solid transparent;
        border-left: 10px solid #00acbd;
        border-bottom: 7px solid transparent;
        right: -20px;
        margin-top: -7px; } }

.page-outpatient .staff-block {
  padding-bottom: 94px; }
  @media screen and (max-width: 769px) {
    .page-outpatient .staff-block {
      padding-bottom: 50px; } }
  .page-outpatient .staff-block .staff {
    display: flex;
    margin-bottom: 50px; }
    @media screen and (max-width: 769px) {
      .page-outpatient .staff-block .staff {
        flex-direction: column;
        margin-bottom: 20px; } }
    .page-outpatient .staff-block .staff:last-child {
      margin-bottom: 60px; }
    .page-outpatient .staff-block .staff__img {
      width: 200px;
      margin-right: 40px;
      overflow: hidden; }
      @media screen and (max-width: 769px) {
        .page-outpatient .staff-block .staff__img {
          margin: 0 auto 15px;
          width: 122px; } }
      .page-outpatient .staff-block .staff__img img {
        width: 100%; }
    .page-outpatient .staff-block .staff__text-area {
      width: calc(100% - 180px);
      font-size: 16px;
      font-size: 1.6rem;
      line-height: 1.5; }
      @media screen and (max-width: 769px) {
        .page-outpatient .staff-block .staff__text-area {
          width: 100%;
          font-size: 14px;
          font-size: 1.4rem; } }
    .page-outpatient .staff-block .staff__item {
      margin-bottom: calc(1em * 1.5);
      font-size: 20px;
      font-size: 2rem; }
      .page-outpatient .staff-block .staff__item:last-child {
        margin-bottom: 0; }
      .page-outpatient .staff-block .staff__item dd {
        overflow: hidden; }
        .page-outpatient .staff-block .staff__item dd .year {
          float: left;
          margin-right: 2em; }
        .page-outpatient .staff-block .staff__item dd .txt {
          overflow: hidden; }
      @media screen and (max-width: 769px) {
        .page-outpatient .staff-block .staff__item {
          margin-bottom: 15px;
          font-size: 15px;
          font-size: 1.5rem; } }
    .page-outpatient .staff-block .staff__head {
      color: #00acbd;
      font-size: 18px;
      font-size: 1.8rem; }
      @media screen and (max-width: 769px) {
        .page-outpatient .staff-block .staff__head {
          font-size: 14px;
          font-size: 1.4rem; } }
    .page-outpatient .staff-block .staff__name {
      padding-bottom: 10px;
      border-bottom: solid 1px #00acbd;
      font-size: 22.5px;
      font-size: 2.25rem; }
      .page-outpatient .staff-block .staff__name span {
        font-size: 30px;
        font-size: 3rem;
        margin-right: 0.5em; }
      @media screen and (max-width: 769px) {
        .page-outpatient .staff-block .staff__name {
          font-size: 13.5px;
          font-size: 1.35rem; }
          .page-outpatient .staff-block .staff__name span {
            font-size: 18px;
            font-size: 1.8rem; } }
    .page-outpatient .staff-block .staff__tag {
      margin-top: 15px; }
      .page-outpatient .staff-block .staff__tag p {
        display: inline-block;
        color: #fff;
        margin-right: 15px;
        padding: 5px 10px 7px;
        font-size: 20px;
        font-size: 2rem;
        line-height: 1; }
        .page-outpatient .staff-block .staff__tag p.green {
          background-color: #84bf40; }
        .page-outpatient .staff-block .staff__tag p.blue {
          background-color: #00acbd; }
      @media screen and (max-width: 769px) {
        .page-outpatient .staff-block .staff__tag {
          margin-top: 10px; }
          .page-outpatient .staff-block .staff__tag p {
            font-size: 15px;
            font-size: 1.5rem;
            margin-right: 5px; } }

.page-outpatient .access-block {
  background: rgba(0, 172, 189, 0.1);
  padding-bottom: 114px; }
  .page-outpatient .access-block .wrap {
    display: flex; }
  .page-outpatient .access-block .map {
    width: 400px;
    margin: 0 auto;
    text-align: center; }
    .page-outpatient .access-block .map img {
      display: block;
      width: 100%; }
  .page-outpatient .access-block .information {
    background: #fff;
    width: 580px;
    padding: 25px 40px 20px;
    box-sizing: border-box; }
  .page-outpatient .access-block .address,
  .page-outpatient .access-block .info {
    font-size: 20px;
    font-size: 2rem;
    line-height: 30px;
    letter-spacing: 0.14em; }
    @media screen and (max-width: 769px) {
      .page-outpatient .access-block .address,
      .page-outpatient .access-block .info {
        font-size: 13px;
        font-size: 1.3rem;
        line-height: 1.7; } }
  .page-outpatient .access-block .address {
    margin-bottom: 15px; }
  .page-outpatient .access-block .info__ttl {
    color: #00acbd;
    font-size: 17.5px;
    font-size: 1.75rem; }
  .page-outpatient .access-block .info__text {
    margin-bottom: 20px; }
    .page-outpatient .access-block .info__text a {
      color: #00acbd; }
    .page-outpatient .access-block .info__text::before {
      content: "□"; }
  @media screen and (max-width: 769px) {
    .page-outpatient .access-block {
      padding-bottom: 57px; }
      .page-outpatient .access-block .wrap {
        display: block; }
      .page-outpatient .access-block .map {
        width: 100%; }
      .page-outpatient .access-block .information {
        width: 100%;
        padding: 25px 18px 20px; }
      .page-outpatient .access-block .info__ttl {
        font-size: 14px;
        font-size: 1.4rem; }
      .page-outpatient .access-block .info__text {
        margin-bottom: 20px; }
        .page-outpatient .access-block .info__text a {
          color: #00acbd; }
        .page-outpatient .access-block .info__text::before {
          content: "□"; } }

.page-outpatient .diagnosis {
  width: 100%;
  padding-bottom: 120px; }
  .page-outpatient .diagnosis .wrap {
    max-width: 850px; }
  .page-outpatient .diagnosis .btn-block {
    margin-top: 40px;
    text-align: left; }
    .page-outpatient .diagnosis .btn-block a {
      margin-left: 0; }
  @media screen and (max-width: 769px) {
    .page-outpatient .diagnosis {
      padding-bottom: 60px; }
      .page-outpatient .diagnosis .btn-block a {
        margin-left: auto; } }

.page-outpatient .diagnosis-block {
  display: flex;
  flex-wrap: wrap;
  margin-top: 75px; }
  @media screen and (max-width: 769px) {
    .page-outpatient .diagnosis-block {
      display: block; } }

.page-outpatient .diagnosis-item {
  display: block;
  width: 45%;
  margin-bottom: 80px; }
  .page-outpatient .diagnosis-item:last-child {
    margin-bottom: 0; }
  @media screen and (max-width: 769px) {
    .page-outpatient .diagnosis-item {
      width: 100%;
      height: auto;
      margin-bottom: 30px; } }
  .page-outpatient .diagnosis-item:nth-child(odd) {
    margin-right: auto; }
  .page-outpatient .diagnosis-item__ttl {
    color: #00acbd;
    font-size: 30px;
    font-size: 3rem;
    text-align: center; }
    .page-outpatient .diagnosis-item__ttl .in {
      display: flex;
      align-items: center; }
      .page-outpatient .diagnosis-item__ttl .in img {
        width: 80px; }
      .page-outpatient .diagnosis-item__ttl .in .txt {
        margin-left: 20px; }
    @media screen and (max-width: 769px) {
      .page-outpatient .diagnosis-item__ttl {
        font-size: 20px;
        font-size: 2rem; }
        .page-outpatient .diagnosis-item__ttl .in {
          display: inline-block;
          position: relative; }
          .page-outpatient .diagnosis-item__ttl .in img {
            position: absolute;
            width: 40px;
            left: -50px;
            top: 50%;
            margin-top: -20px; }
          .page-outpatient .diagnosis-item__ttl .in .txt {
            margin-left: 0; } }
  .page-outpatient .diagnosis-item__txt {
    margin-top: 10px;
    font-size: 16px;
    font-size: 1.6rem; }
    @media screen and (max-width: 769px) {
      .page-outpatient .diagnosis-item__txt {
        font-size: 14px; } }
    .page-outpatient .diagnosis-item__txt--link a {
      color: #00acbd;
      font-size: 12px; }

.page-about .kv {
  background: url(../img/about/key_visual.png) no-repeat center center;
  background-size: cover; }

.page-about .content {
  counter-reset: number; }
  .page-about .content__ttl {
    padding: 45px 0 30px;
    text-align: center;
    font-size: 30px;
    font-size: 3rem;
    font-weight: normal;
    letter-spacing: 0.12em; }
    @media screen and (max-width: 769px) {
      .page-about .content__ttl {
        padding: 15px 0;
        font-size: 20px;
        font-size: 2rem; } }

@media screen and (min-width: 770px) {
  .page-about .wrap {
    width: 900px;
    margin: 0 auto; } }

@media screen and (max-width: 769px) {
  .page-about .wrap {
    width: auto;
    margin: 0 5%; } }

.page-about .lead__text {
  font-size: 16px;
  font-size: 1.6rem; }
  @media screen and (max-width: 769px) {
    .page-about .lead__text {
      font-size: 14px;
      font-size: 1.4rem; } }
  .page-about .lead__text a {
    color: #00acbd;
    text-decoration: underline; }
  .page-about .lead__text--name {
    margin: 20px 0;
    text-align: right;
    font-size: 16px;
    font-size: 1.6rem; }
    @media screen and (max-width: 769px) {
      .page-about .lead__text--name {
        font-size: 14px;
        font-size: 1.4rem; } }
    .page-about .lead__text--name span {
      margin-right: 1em;
      color: #00acbd;
      font-size: 13px;
      font-size: 1.3rem; }
      @media screen and (max-width: 769px) {
        .page-about .lead__text--name span {
          margin-right: 0.5em;
          font-size: 11px;
          font-size: 1.1rem; } }

.page-about .table {
  width: 100%;
  border: solid 1px #00acbd;
  margin-bottom: 65px;
  font-size: 16px;
  font-size: 1.6rem; }
  @media screen and (max-width: 769px) {
    .page-about .table {
      font-size: 14px;
      font-size: 1.4rem; } }
  .page-about .table th,
  .page-about .table td {
    padding: 15px 20px; }
    @media screen and (max-width: 769px) {
      .page-about .table th,
      .page-about .table td {
        padding: 10px; } }
  .page-about .table th {
    width: 28%;
    background: #00acbd;
    color: #fff;
    border-bottom: solid 1px #fff; }
  .page-about .table td {
    border-bottom: solid 1px #00acbd; }
    .page-about .table td a {
      color: #00acbd; }

.page-staff .kv {
  background: url(../img/staff/key_visual.png) no-repeat center center;
  background-size: cover; }

.page-staff .content {
  counter-reset: number; }
  .page-staff .content__ttl {
    padding: 45px 0 30px;
    text-align: center;
    font-size: 30px;
    font-size: 3rem;
    font-weight: normal;
    letter-spacing: 0.12em; }
    @media screen and (max-width: 769px) {
      .page-staff .content__ttl {
        padding: 15px 0;
        font-size: 20px;
        font-size: 2rem; } }

@media screen and (min-width: 770px) {
  .page-staff .wrap {
    width: 900px;
    margin: 0 auto; } }

@media screen and (max-width: 769px) {
  .page-staff .wrap {
    width: auto;
    margin: 0 5%; } }

.page-staff .staff {
  display: flex;
  margin-bottom: 50px; }
  @media screen and (max-width: 769px) {
    .page-staff .staff {
      flex-direction: column; } }
  .page-staff .staff:last-child {
    margin-bottom: 60px; }
  .page-staff .staff__img {
    width: 150px;
    height: 200px;
    margin-right: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; }
    @media screen and (max-width: 769px) {
      .page-staff .staff__img {
        margin: 0 auto 15px; } }
    .page-staff .staff__img img {
      width: 100%; }
  .page-staff .staff__text-area {
    width: calc(100% - 180px);
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.5; }
    @media screen and (max-width: 769px) {
      .page-staff .staff__text-area {
        width: 100%;
        font-size: 14px;
        font-size: 1.4rem; } }
  .page-staff .staff__item {
    margin-bottom: calc(1em * 1.5); }
    .page-staff .staff__item:last-child {
      margin-bottom: 0; }
  .page-staff .staff__head {
    color: #00acbd; }
  .page-staff .staff__name {
    padding-bottom: 10px;
    border-bottom: solid 1px #00acbd; }
    .page-staff .staff__name span {
      font-size: 20px;
      font-size: 2rem;
      margin-right: 0.5em; }
      @media screen and (max-width: 769px) {
        .page-staff .staff__name span {
          font-size: 18px;
          font-size: 1.8rem; } }

.page-visiting-care .kv {
  background: url(../img/visiting_care/key_visual.png) no-repeat center center;
  background-size: cover; }

.page-visiting-care .content {
  counter-reset: number; }
  .page-visiting-care .content__ttl {
    padding: 45px 0 30px;
    text-align: center;
    font-size: 30px;
    font-size: 3rem;
    font-weight: normal;
    letter-spacing: 0.12em; }
    @media screen and (max-width: 769px) {
      .page-visiting-care .content__ttl {
        padding: 15px 0;
        font-size: 20px;
        font-size: 2rem; } }

@media screen and (min-width: 770px) {
  .page-visiting-care .wrap {
    width: 800px;
    margin: 0 auto; } }

@media screen and (max-width: 769px) {
  .page-visiting-care .wrap {
    width: auto;
    margin: 0 5%; } }

.page-visiting-care .block {
  margin-bottom: 120px; }
  @media screen and (max-width: 769px) {
    .page-visiting-care .block {
      margin-bottom: 60px; } }
  .page-visiting-care .block__ttl {
    margin-bottom: 30px;
    font-size: 30px;
    font-size: 3rem;
    letter-spacing: 0.12em;
    text-align: center; }
    @media screen and (max-width: 769px) {
      .page-visiting-care .block__ttl {
        margin-bottom: 15px;
        font-size: 20px;
        font-size: 2rem; } }
    .page-visiting-care .block__ttl span {
      position: relative;
      display: inline-block;
      padding: 0 260px;
      width: 280px; }
      @media screen and (max-width: 769px) {
        .page-visiting-care .block__ttl span {
          padding: 0 54px;
          width: 180px; } }
      .page-visiting-care .block__ttl span::before, .page-visiting-care .block__ttl span::after {
        content: '';
        position: absolute;
        top: 50%;
        display: inline-block;
        width: 260px;
        height: 1px;
        background-color: #00acbd; }
        @media screen and (max-width: 769px) {
          .page-visiting-care .block__ttl span::before, .page-visiting-care .block__ttl span::after {
            width: 54px; } }
      .page-visiting-care .block__ttl span::before {
        left: 0; }
      .page-visiting-care .block__ttl span:after {
        right: 0; }

.page-visiting-care .tel-info {
  width: 740px;
  box-sizing: border-box;
  margin-right: auto;
  margin-left: auto;
  padding: 40px 70px;
  border-radius: 7px;
  line-height: 1.4;
  background: rgba(0, 172, 189, 0.1);
  font-size: 50px;
  font-size: 5rem; }
  @media screen and (max-width: 769px) {
    .page-visiting-care .tel-info {
      width: auto;
      padding: 20px 5%;
      font-size: 30px;
      font-size: 3rem; } }
  .page-visiting-care .tel-info__item {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: solid 1px #00acbd; }
    .page-visiting-care .tel-info__item:last-child {
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: none; }
    .page-visiting-care .tel-info__item .note {
      font-size: 17px;
      font-size: 1.7rem; }
      @media screen and (max-width: 769px) {
        .page-visiting-care .tel-info__item .note {
          font-size: 12px;
          font-size: 1.2rem; } }
  .page-visiting-care .tel-info .item--tel::before {
    content: "";
    display: block;
    width: 56px;
    height: 56px;
    margin-right: 30px;
    background: url(../img/visiting_care/icon_tel.png) no-repeat;
    background-size: contain; }
    @media screen and (max-width: 769px) {
      .page-visiting-care .tel-info .item--tel::before {
        width: 28px;
        height: 28px;
        margin-right: 5%; } }
  .page-visiting-care .tel-info .item--tel a {
    display: block; }
    .page-visiting-care .tel-info .item--tel a span {
      display: block;
      font-size: 12px; }
  .page-visiting-care .tel-info .item--fax::before {
    content: "";
    display: block;
    width: 56px;
    height: 81px;
    margin-right: 30px;
    background: url(../img/visiting_care/icon_fax.png) no-repeat;
    background-size: contain; }
    @media screen and (max-width: 769px) {
      .page-visiting-care .tel-info .item--fax::before {
        width: 28px;
        height: 40px;
        margin-right: 5%; } }
  .page-visiting-care .tel-info .item--fax a {
    display: block; }

.page-visiting-care .area {
  display: flex; }
  @media screen and (max-width: 769px) {
    .page-visiting-care .area {
      flex-direction: column; } }
  .page-visiting-care .area__img {
    width: 400px;
    margin-right: 30px; }
    @media screen and (max-width: 769px) {
      .page-visiting-care .area__img {
        width: 100%;
        margin: 0 0 15px; } }
    .page-visiting-care .area__img img {
      display: block;
      width: 100%; }
  .page-visiting-care .area__text-area {
    width: calc(100% - 430px); }
    @media screen and (max-width: 769px) {
      .page-visiting-care .area__text-area {
        width: 100%; } }
  .page-visiting-care .area__item {
    width: 100%;
    font-size: 22px;
    font-size: 2.2rem;
    line-height: 1.75;
    margin-bottom: calc(1em * 1.75); }
    @media screen and (max-width: 769px) {
      .page-visiting-care .area__item {
        font-size: 16px;
        font-size: 1.6rem; } }
  .page-visiting-care .area__head {
    color: #00acbd; }

.page-visiting-care .point__item {
  display: flex;
  margin-bottom: 60px; }
  .page-visiting-care .point__item:last-child {
    margin-bottom: 0; }

.page-visiting-care .point__img {
  width: 160px;
  margin-right: 40px; }
  @media screen and (max-width: 769px) {
    .page-visiting-care .point__img {
      width: 80px;
      margin-right: 20px; } }
  .page-visiting-care .point__img img {
    display: block;
    width: 100%; }

.page-visiting-care .point__text-area {
  width: calc(100% - 200px);
  margin-top: 10px; }
  @media screen and (max-width: 769px) {
    .page-visiting-care .point__text-area {
      width: calc(100% - 100px);
      margin-top: 0; } }

.page-visiting-care .point__head {
  margin-bottom: 10px;
  font-size: 26px;
  font-size: 2.6rem;
  color: #00acbd; }
  @media screen and (max-width: 769px) {
    .page-visiting-care .point__head {
      margin-bottom: 5px;
      font-size: 18px;
      font-size: 1.8rem; } }

.page-visiting-care .point__text {
  font-size: 18px;
  font-size: 1.8rem; }
  @media screen and (max-width: 769px) {
    .page-visiting-care .point__text {
      font-size: 14px;
      font-size: 1.4rem; } }

.page-visiting-care .lead__text {
  font-size: 18px;
  font-size: 1.8rem; }
  @media screen and (max-width: 769px) {
    .page-visiting-care .lead__text {
      font-size: 14px;
      font-size: 1.4rem; } }
  .page-visiting-care .lead__text a {
    color: #00acbd;
    text-decoration: underline; }

.page-visiting-care .list-area {
  width: 700px;
  margin: 0 auto 70px; }
  @media screen and (max-width: 769px) {
    .page-visiting-care .list-area {
      width: 100%; } }
  .page-visiting-care .list-area__notes {
    font-size: 12px;
    margin-top: 5px; }
  .page-visiting-care .list-area:last-child {
    margin-bottom: 0; }
  .page-visiting-care .list-area__ttl {
    width: 185px;
    margin: 0 auto 30px;
    background: #00acbd;
    color: #fff;
    text-align: center;
    font-size: 20px;
    font-size: 2rem;
    border-radius: 6px;
    line-height: 2.2; }
    @media screen and (max-width: 769px) {
      .page-visiting-care .list-area__ttl {
        font-size: 18px;
        font-size: 1.8rem; } }
  .page-visiting-care .list-area .list {
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    border: solid 1px #00acbd; }
    .page-visiting-care .list-area .list li {
      box-sizing: border-box;
      width: 50%;
      padding: 10px;
      font-size: 18px;
      font-size: 1.8rem;
      border-bottom: solid 1px #00acbd; }
      @media screen and (max-width: 769px) {
        .page-visiting-care .list-area .list li {
          font-size: 14px;
          font-size: 1.4rem; } }
      .page-visiting-care .list-area .list li:nth-child(odd) {
        border-right: solid 1px #00acbd; }
      .page-visiting-care .list-area .list li:nth-child(4n-1), .page-visiting-care .list-area .list li:nth-child(4n) {
        background: rgba(0, 172, 189, 0.1); }
      .page-visiting-care .list-area .list li:last-child, .page-visiting-care .list-area .list li:nth-last-child(2) {
        border-bottom: none; }
  .page-visiting-care .list-area .note {
    font-size: 14px;
    font-size: 1.4rem; }
    @media screen and (max-width: 769px) {
      .page-visiting-care .list-area .note {
        font-size: 12px;
        font-size: 1.2rem; } }

.page-visiting-care .flow {
  width: 745px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between; }
  @media screen and (max-width: 769px) {
    .page-visiting-care .flow {
      width: 100%;
      flex-direction: column; } }
  .page-visiting-care .flow__item {
    position: relative;
    width: 180px; }
    @media screen and (max-width: 769px) {
      .page-visiting-care .flow__item {
        padding-bottom: 40px;
        margin-bottom: 20px;
        width: 100%; } }
    .page-visiting-care .flow__item:last-child::after {
      display: none; }
    @media screen and (max-width: 769px) {
      .page-visiting-care .flow__item:last-child {
        padding-bottom: 0;
        margin-bottom: 0; } }
    .page-visiting-care .flow__item::after {
      position: absolute;
      right: -78px;
      top: 40px;
      content: "";
      display: block;
      width: 50px;
      height: 44px;
      background: url(../img/visiting_care/icon_arrow.png) no-repeat;
      background-size: contain; }
      @media screen and (max-width: 769px) {
        .page-visiting-care .flow__item::after {
          right: 0;
          left: 0;
          top: inherit;
          bottom: 0;
          margin: auto;
          width: 25px;
          height: 22px;
          transform: rotate(90deg); } }
  .page-visiting-care .flow__ttl {
    margin-bottom: 10px;
    text-align: center;
    font-size: 20px;
    font-size: 2rem;
    color: #00acbd; }
    @media screen and (max-width: 769px) {
      .page-visiting-care .flow__ttl {
        font-size: 18px;
        font-size: 1.8rem; } }
    .page-visiting-care .flow__ttl .note {
      font-size: 16px;
      font-size: 1.6rem; }
      @media screen and (max-width: 769px) {
        .page-visiting-care .flow__ttl .note {
          font-size: 14px;
          font-size: 1.4rem; } }
  .page-visiting-care .flow__img {
    display: block;
    width: 98px;
    margin: 0 auto 10px; }
  .page-visiting-care .flow__text {
    font-size: 15px;
    font-size: 1.5rem; }
    @media screen and (max-width: 769px) {
      .page-visiting-care .flow__text {
        font-size: 14px;
        font-size: 1.4rem; } }

.page-recruit .kv {
  background: url(../img/recruit/key_visual.png) no-repeat center center;
  background-size: cover; }

.page-recruit .content {
  counter-reset: number; }
  .page-recruit .content__ttl {
    padding: 45px 0 30px;
    text-align: center;
    font-size: 30px;
    font-size: 3rem;
    font-weight: normal;
    letter-spacing: 0.12em; }
    @media screen and (max-width: 769px) {
      .page-recruit .content__ttl {
        padding: 15px 0;
        font-size: 20px;
        font-size: 2rem; } }

@media screen and (min-width: 770px) {
  .page-recruit .wrap {
    width: 1024px;
    margin: 0 auto; } }

@media screen and (max-width: 769px) {
  .page-recruit .wrap {
    width: auto;
    margin: 0 5%; } }

.page-recruit .recruit {
  margin-bottom: 120px; }
  .page-recruit .recruit__ttl {
    margin-bottom: 30px;
    font-size: 23px;
    font-size: 2.3rem;
    text-align: center; }
    .page-recruit .recruit__ttl__img {
      width: 60px;
      margin: 0 auto 10px; }
      .page-recruit .recruit__ttl__img img {
        width: 100%;
        display: block; }

.page-recruit .recruit_position > p {
  margin-bottom: 30px;
  text-align: center;
  font-size: 14px;
  font-size: 1.4rem; }

.page-recruit .recruit_position > ul > li {
  margin-bottom: 30px; }

.page-recruit .recruit_position > ul > li > a {
  display: flex;
  justify-content: space-between;
  background-color: #eeeeee;
  padding: 10px 20px 10px 10px; }

.page-recruit .recruit_position > ul > li > a:hover {
  opacity: 0.7;
  text-decoration: none; }

.page-recruit .recruit_position > ul > li > a .recruit_position_list_img {
  width: 391px; }

.page-recruit .recruit_position > ul > li > a .recruit_position_list_txt {
  width: 615px;
  padding: 10px 0; }

.page-recruit .recruit_position > ul > li > a .recruit_position_list_txt h3 {
  margin-bottom: 15px;
  color: #00acbd;
  font-weight: 500;
  font-size: 20px; }

.page-recruit .recruit_position > ul > li > a .recruit_position_list_txt p {
  color: #333;
  line-height: 1.5;
  font-size: 16px; }

.page-recruit .recruit_position > ul > li > a .recruit_position_list_more {
  position: relative;
  margin-bottom: 15px;
  padding-right: 10px;
  color: #00acbd;
  text-align: right;
  font-size: 14px; }
  .page-recruit .recruit_position > ul > li > a .recruit_position_list_more::after {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 2px;
    margin: auto;
    vertical-align: middle;
    content: "";
    width: 6px;
    height: 6px;
    border-top: 2px solid #00acbd;
    border-right: 2px solid #00acbd;
    transform: rotate(45deg); }
    @media screen and (max-width: 769px) {
      .page-recruit .recruit_position > ul > li > a .recruit_position_list_more::after {
        width: 5px;
        height: 5px; } }

.page-recruit .recruit_position > ul > li > a .recruit_position_list_tag li {
  display: inline-block;
  margin: 0 5px 5px 0;
  border-radius: 1em;
  background-color: #00acbd;
  padding: 0.5em 1em;
  color: #fff;
  line-height: 1;
  text-align: center;
  font-size: 16px; }

@media screen and (max-width: 769px) {
  .page-recruit .recruit_position {
    margin: 0 auto;
    width: 100%; }
  .page-recruit .recruit_position > p {
    margin-bottom: 10px; }
  .page-recruit .recruit_position > ul > li {
    margin-bottom: 15px; }
  .page-recruit .recruit_position > ul > li > a {
    display: block;
    padding: 10px; }
  .page-recruit .recruit_position > ul > li > a .recruit_position_list_img {
    display: none; }
  .page-recruit .recruit_position > ul > li > a .recruit_position_list_txt {
    width: 100%;
    padding: 0; }
  .page-recruit .recruit_position > ul > li > a .recruit_position_list_txt h3 {
    margin-bottom: 10px;
    font-size: 14px; }
  .page-recruit .recruit_position > ul > li > a .recruit_position_list_txt p {
    font-size: 12px; }
  .page-recruit .recruit_position > ul > li > a .recruit_position_list_more {
    margin-bottom: 10px;
    font-size: 10px; }
  .page-recruit .recruit_position > ul > li > a .recruit_position_list_tag li {
    font-size: 11px; } }

.page-medicalexamination {
  letter-spacing: 0.11em; }
  .page-medicalexamination .kv {
    background: url(../img/medicalexamination/key_visual.png) no-repeat center center;
    background-size: cover;
    width: 100%; }
  @media screen and (min-width: 770px) {
    .page-medicalexamination .wrap {
      width: 980px;
      margin-left: auto;
      margin-right: auto; } }
  @media screen and (max-width: 769px) {
    .page-medicalexamination .wrap {
      width: auto;
      margin-left: 5%;
      margin-right: 5%; } }
  .page-medicalexamination .content {
    font-size: 20px;
    font-size: 2rem;
    padding-bottom: 50px; }
    @media screen and (max-width: 769px) {
      .page-medicalexamination .content {
        font-size: 14px;
        font-size: 1.4rem;
        padding-bottom: 0; } }
    .page-medicalexamination .content__ttl {
      padding: 45px 0 30px;
      text-align: center;
      font-size: 36px;
      font-size: 3.6rem;
      font-weight: normal;
      letter-spacing: 0.12em; }
      @media screen and (max-width: 769px) {
        .page-medicalexamination .content__ttl {
          padding: 15px 0;
          font-size: 21px;
          font-size: 2.1rem; } }
    .page-medicalexamination .content__lead {
      width: 750px;
      margin-left: auto;
      margin-right: auto; }
      @media screen and (max-width: 769px) {
        .page-medicalexamination .content__lead {
          width: 100%;
          padding: 0 5%;
          box-sizing: border-box; } }
    .page-medicalexamination .content__navi a:nth-of-type(1):before {
      background-image: url(../img/medicalexamination/icon_01.png); }
    .page-medicalexamination .content__navi a:nth-of-type(1) span {
      padding-left: 10px; }
    .page-medicalexamination .content__navi a:nth-of-type(2) {
      letter-spacing: 0.07em; }
      .page-medicalexamination .content__navi a:nth-of-type(2):before {
        background-image: url(../img/medicalexamination/icon_02.png); }
      .page-medicalexamination .content__navi a:nth-of-type(2) span {
        padding-left: 20px; }
    .page-medicalexamination .content__navi a:nth-of-type(3):before {
      width: 25px;
      background-image: url(../img/medicalexamination/icon_03.png); }
    .page-medicalexamination .content__navi a:nth-of-type(3) span {
      padding-left: 20px; }
    .page-medicalexamination .content__navi a:nth-of-type(4):before {
      width: 25px;
      background-image: url(../img/medicalexamination/icon_04.png); }
    .page-medicalexamination .content__navi a:nth-of-type(4) span {
      padding-left: 20px; }
    @media screen and (max-width: 769px) {
      .page-medicalexamination .content__navi a:nth-of-type(1):before {
        width: 23px; }
      .page-medicalexamination .content__navi a:nth-of-type(2):before {
        width: 18px; }
      .page-medicalexamination .content__navi a:nth-of-type(2) span {
        padding-left: 30px; }
      .page-medicalexamination .content__navi a:nth-of-type(3):before {
        width: 17px; }
      .page-medicalexamination .content__navi a:nth-of-type(3) span {
        padding-left: 10px; }
      .page-medicalexamination .content__navi a:nth-of-type(4):before {
        width: 17px; } }
  .page-medicalexamination .tel-info {
    width: 750px;
    box-sizing: border-box;
    margin: 30px auto 50px;
    padding: 20px;
    border-radius: 7px;
    line-height: 1.4;
    background: rgba(0, 172, 189, 0.1);
    font-size: 50px;
    font-size: 5rem; }
    @media screen and (max-width: 769px) {
      .page-medicalexamination .tel-info {
        width: auto;
        margin-bottom: 30px;
        padding: 15px 5%;
        font-size: 30px;
        font-size: 3rem; } }
    .page-medicalexamination .tel-info .tti {
      text-align: center;
      font-size: 21px;
      font-size: 2.1rem; }
    .page-medicalexamination .tel-info__item {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center; }
      .page-medicalexamination .tel-info__item .note {
        font-size: 17px;
        font-size: 1.7rem; }
    .page-medicalexamination .tel-info .item--tel::before {
      content: "";
      display: block;
      width: 56px;
      height: 56px;
      margin-right: 30px;
      background: url(../img/visiting_care/icon_tel.png) no-repeat;
      background-size: contain; }
      @media screen and (max-width: 769px) {
        .page-medicalexamination .tel-info .item--tel::before {
          width: 28px;
          height: 28px;
          margin-right: 5%; } }
    .page-medicalexamination .tel-info .item--tel a {
      display: block; }
      .page-medicalexamination .tel-info .item--tel a span {
        display: block;
        font-size: 12px; }
    @media screen and (max-width: 769px) {
      .page-medicalexamination .tel-info {
        border-radius: 0px; }
        .page-medicalexamination .tel-info .tti {
          font-size: 11px;
          font-size: 1.1rem; }
        .page-medicalexamination .tel-info__item .note {
          font-size: 12px;
          font-size: 1.2rem; } }
  .page-medicalexamination .feature-block {
    background: rgba(0, 172, 189, 0.1);
    padding-bottom: 100px; }
    .page-medicalexamination .feature-block ul {
      margin-top: 26px; }
      .page-medicalexamination .feature-block ul li {
        margin-top: 24px;
        padding: 30px;
        background: #fff;
        border-radius: 10px; }
        .page-medicalexamination .feature-block ul li h3 {
          font-size: 30px;
          font-size: 3rem;
          font-weight: bold;
          color: #00acbd; }
        .page-medicalexamination .feature-block ul li .txt {
          margin-top: 15px;
          font-size: 18px;
          font-size: 1.8rem; }
          .page-medicalexamination .feature-block ul li .txt .arrow {
            display: inline-block;
            margin-right: 0.5em;
            color: #00acbd;
            font-size: 80%; }
    @media screen and (max-width: 769px) {
      .page-medicalexamination .feature-block {
        padding-bottom: 40px; }
        .page-medicalexamination .feature-block figure img {
          max-width: 275px;
          margin-left: auto;
          margin-right: auto; }
        .page-medicalexamination .feature-block ul li {
          padding: 15px; }
          .page-medicalexamination .feature-block ul li h3 {
            text-align: center;
            font-size: 21px;
            font-size: 2.1rem;
            line-height: 1.3; }
          .page-medicalexamination .feature-block ul li .txt {
            margin-top: 10px;
            font-size: 14px;
            font-size: 1.4rem; } }
  .page-medicalexamination .menu-block {
    padding-bottom: 150px; }
    @media screen and (max-width: 769px) {
      .page-medicalexamination .menu-block {
        padding-bottom: 60px; } }
    .page-medicalexamination .menu-block p {
      padding: 0 25px; }
      @media screen and (max-width: 769px) {
        .page-medicalexamination .menu-block p {
          padding: 0; } }
    .page-medicalexamination .menu-block h3 {
      margin-top: 60px;
      margin-bottom: 40px;
      text-align: center;
      font-size: 36px;
      font-size: 3.6rem; }
      @media screen and (max-width: 769px) {
        .page-medicalexamination .menu-block h3 {
          margin-top: 30px;
          margin-bottom: 10px;
          font-size: 21px;
          font-size: 2.1rem; } }
    .page-medicalexamination .menu-block .price-time {
      background: rgba(136, 194, 63, 0.25);
      border-radius: 10px;
      margin-top: 20px;
      padding: 30px;
      position: relative; }
      .page-medicalexamination .menu-block .price-time:before {
        content: "";
        width: calc(100% - 8px);
        height: calc(100% - 8px);
        position: absolute;
        left: 4px;
        top: 4px;
        border: solid 1px #fff;
        border-radius: 6px;
        box-sizing: border-box; }
      .page-medicalexamination .menu-block .price-time__in {
        display: flex;
        justify-content: center;
        position: relative;
        z-index: 1; }
        .page-medicalexamination .menu-block .price-time__in li {
          display: flex;
          align-items: center;
          line-height: 1; }
          .page-medicalexamination .menu-block .price-time__in li:first-child {
            margin-right: 50px; }
          .page-medicalexamination .menu-block .price-time__in li .item {
            background: #88c23f;
            color: #fff;
            margin-right: 30px;
            padding: 8px 10px 10px;
            font-size: 24px;
            font-size: 2.4rem;
            border-radius: 4px;
            line-height: 20px; }
          .page-medicalexamination .menu-block .price-time__in li .big {
            font-size: 36px;
            font-size: 3.6rem;
            margin: 0 0.2em; }
          .page-medicalexamination .menu-block .price-time__in li .sml {
            font-size: 18px;
            font-size: 1.8rem;
            letter-spacing: 0; }
      @media screen and (max-width: 769px) {
        .page-medicalexamination .menu-block .price-time {
          padding: 15px;
          border-radius: 6px; }
          .page-medicalexamination .menu-block .price-time:before {
            content: "";
            width: calc(100% - 4px);
            height: calc(100% - 4px);
            left: 2px;
            top: 2px;
            border-radius: 4px; }
          .page-medicalexamination .menu-block .price-time__in li:first-child {
            margin-right: 20px; }
          .page-medicalexamination .menu-block .price-time__in li .item {
            margin-right: 10px;
            padding: 4px 6px 6px;
            font-size: 12px;
            font-size: 1.2rem;
            border-radius: 2px;
            line-height: 12px; }
          .page-medicalexamination .menu-block .price-time__in li .big {
            font-size: 18px;
            font-size: 1.8rem;
            margin: 0 0.2em; }
          .page-medicalexamination .menu-block .price-time__in li .sml {
            font-size: 10px;
            font-size: 1rem;
            letter-spacing: 0; } }
    .page-medicalexamination .menu-block .acc-block {
      margin-top: 50px; }
      .page-medicalexamination .menu-block .acc-block.light-green .table-title {
        background: #88c23f; }
      @media screen and (max-width: 769px) {
        .page-medicalexamination .menu-block .acc-block {
          margin-top: 25px; } }
    .page-medicalexamination .menu-block .table-title {
      color: #fff;
      text-align: center;
      height: 50px;
      line-height: 48px;
      font-size: 24px;
      font-size: 2.4rem;
      position: relative;
      cursor: pointer; }
      .page-medicalexamination .menu-block .table-title.is-open span.open {
        display: none; }
      .page-medicalexamination .menu-block .table-title.is-open span.close {
        display: block; }
      .page-medicalexamination .menu-block .table-title span {
        display: none;
        font-size: 20px;
        font-size: 2rem;
        padding-right: 30px;
        position: absolute;
        right: 20px;
        top: 0; }
        .page-medicalexamination .menu-block .table-title span.open {
          display: block; }
          .page-medicalexamination .menu-block .table-title span.open:after {
            transform: rotate(-45deg); }
        .page-medicalexamination .menu-block .table-title span.close:after {
          transform: rotate(45deg);
          margin-top: -7px; }
        .page-medicalexamination .menu-block .table-title span:after {
          content: "";
          width: 10px;
          height: 10px;
          border-right: solid 3px #fff;
          border-bottom: solid 3px #fff;
          position: absolute;
          right: 0px;
          top: 50%;
          margin-top: -5px; }
      @media screen and (max-width: 769px) {
        .page-medicalexamination .menu-block .table-title {
          display: none; } }
    .page-medicalexamination .menu-block .table-wrap {
      display: none; }
      .page-medicalexamination .menu-block .table-wrap .sp-title {
        display: none; }
      .page-medicalexamination .menu-block .table-wrap table .width-set {
        width: 180px; }
      @media screen and (max-width: 769px) {
        .page-medicalexamination .menu-block .table-wrap {
          display: block; }
          .page-medicalexamination .menu-block .table-wrap .sp-title {
            display: block;
            background: #88c23f;
            color: #fff;
            font-size: 14px;
            font-size: 1.4rem;
            padding: 5px 10px;
            border-top: solid 1px #796a56;
            border-right: solid 1px #796a56;
            border-left: solid 1px #796a56;
            width: 600px;
            box-sizing: border-box; }
          .page-medicalexamination .menu-block .table-wrap table {
            width: 600px; }
            .page-medicalexamination .menu-block .table-wrap table .width-set {
              width: 100px; } }
  .page-medicalexamination .table-wrap table {
    width: 100%;
    border: solid 1px #796a56; }
    .page-medicalexamination .table-wrap table.green th {
      background: #008e5c;
      color: #fff;
      text-align: left; }
    .page-medicalexamination .table-wrap table th, .page-medicalexamination .table-wrap table td {
      background: #fff;
      padding: 6px 10px;
      font-size: 20px;
      font-size: 2rem;
      vertical-align: top;
      letter-spacing: 0;
      border-bottom: solid 1px #796a56; }
      .page-medicalexamination .table-wrap table th.center, .page-medicalexamination .table-wrap table td.center {
        text-align: center; }
      .page-medicalexamination .table-wrap table th:empty, .page-medicalexamination .table-wrap table td:empty {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHN0eWxlPSJ3aWR0aDoxMDAlO2hlaWdodDoxMDAlOyI+PGxpbmUgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMTAwJSIgc3R5bGU9InN0cm9rZTogIzMzMzMzMztzdHJva2Utd2lkdGg6IDE7Ij48L2xpbmU+PC9zdmc+"); }
    .page-medicalexamination .table-wrap table th {
      border-right: none; }
    .page-medicalexamination .table-wrap table td {
      border-right: solid 1px #796a56; }
  @media screen and (max-width: 769px) {
    .page-medicalexamination .table-wrap {
      overflow: scroll;
      -webkit-overflow-scrolling: touch;
      overflow-scrolling: touch;
      -webkit-text-size-adjust: 100%; }
      .page-medicalexamination .table-wrap table th, .page-medicalexamination .table-wrap table td {
        font-size: 12px;
        font-size: 1.2rem; } }
  .page-medicalexamination .option-block {
    background: #e5f7f8;
    padding-bottom: 100px; }
    .page-medicalexamination .option-block p {
      padding: 0 25px; }
    .page-medicalexamination .option-block .table-wrap {
      margin-top: 10px;
      margin-bottom: 30px; }
      .page-medicalexamination .option-block .table-wrap table th:last-child, .page-medicalexamination .option-block .table-wrap table td:last-child {
        text-align: right;
        padding: 6px 15px; }
      .page-medicalexamination .option-block .table-wrap table .width-set {
        width: 150px; }
    @media screen and (max-width: 769px) {
      .page-medicalexamination .option-block {
        padding-bottom: 50px; }
        .page-medicalexamination .option-block p {
          padding: 0; }
        .page-medicalexamination .option-block .table-wrap table.table1 {
          width: 460px; }
        .page-medicalexamination .option-block .table-wrap table.table2 {
          width: 610px; }
        .page-medicalexamination .option-block .table-wrap table .width-set {
          width: 80px; } }
  .page-medicalexamination .contact-block {
    text-align: center; }
    .page-medicalexamination .contact-block h3 {
      position: relative;
      text-align: center; }
      .page-medicalexamination .contact-block h3:before {
        content: "";
        width: 100%;
        height: 2px;
        background-image: linear-gradient(to right, #00acbd, #00acbd 2px, transparent 2px, transparent 10px);
        background-size: 10px 2px;
        background-repeat: repeat-x;
        position: absolute;
        left: 0;
        top: 50%;
        z-index: 0; }
      .page-medicalexamination .contact-block h3 span {
        display: inline-block;
        background: #fff;
        padding: 0 1em;
        font-size: 36px;
        font-size: 3.6rem;
        line-height: 1;
        position: relative;
        z-index: 1; }
      @media screen and (max-width: 769px) {
        .page-medicalexamination .contact-block h3:before {
          background-image: linear-gradient(to right, #00acbd, #00acbd 1px, transparent 1px, transparent 5px);
          background-size: 5px 1px; }
        .page-medicalexamination .contact-block h3 span {
          font-size: 21px;
          font-size: 2.1rem; } }
    .page-medicalexamination .contact-block .lead {
      margin-top: 30px;
      font-size: 30px;
      font-size: 3rem; }
      .page-medicalexamination .contact-block .lead .color {
        color: #00acbd; }
      @media screen and (max-width: 769px) {
        .page-medicalexamination .contact-block .lead {
          margin-top: 15px;
          text-align: left;
          font-size: 18px;
          font-size: 1.8rem; } }
    .page-medicalexamination .contact-block .note {
      font-size: 20px;
      font-size: 2rem; }
      @media screen and (max-width: 769px) {
        .page-medicalexamination .contact-block .note {
          font-size: 12px;
          font-size: 1.2rem; } }
    .page-medicalexamination .contact-block table {
      width: 100%;
      max-width: 800px;
      margin-top: 30px;
      margin-left: auto;
      margin-right: auto;
      border: solid 1px #6f6f6f; }
      .page-medicalexamination .contact-block table th, .page-medicalexamination .contact-block table td {
        border-right: solid 1px #6f6f6f;
        border-bottom: solid 1px #6f6f6f;
        padding: 15px 20px;
        text-align: center;
        line-height: 1.5; }
      .page-medicalexamination .contact-block table th {
        background: #00acbd;
        color: #fff;
        font-size: 22px;
        font-size: 2.2rem;
        width: 11.5%; }
        .page-medicalexamination .contact-block table th:first-child {
          width: 31%; }
      .page-medicalexamination .contact-block table td {
        font-size: 36px;
        font-size: 3.6rem;
        color: #00acbd; }
        .page-medicalexamination .contact-block table td:first-child {
          font-size: 25px;
          font-size: 2.5rem;
          text-align: left;
          color: #333; }
        .page-medicalexamination .contact-block table td .sml {
          color: #6d6d6d;
          font-size: 22px;
          font-size: 2.2rem; }
      @media screen and (max-width: 769px) {
        .page-medicalexamination .contact-block table {
          margin-top: 15px; }
          .page-medicalexamination .contact-block table th, .page-medicalexamination .contact-block table td {
            padding: 6px;
            letter-spacing: 0; }
          .page-medicalexamination .contact-block table th {
            font-size: 14px;
            font-size: 1.4rem; }
          .page-medicalexamination .contact-block table td {
            font-size: 18px;
            font-size: 1.8rem; }
            .page-medicalexamination .contact-block table td:first-child {
              font-size: 13.5px;
              font-size: 1.35rem; }
            .page-medicalexamination .contact-block table td .sml {
              font-size: 10px;
              font-size: 1rem; } }
    .page-medicalexamination .contact-block .arrow {
      width: 100%;
      height: 40px;
      margin: 40px auto;
      position: relative; }
      .page-medicalexamination .contact-block .arrow:before {
        content: "";
        width: 46px;
        height: 46px;
        border-right: solid 6px #00acbd;
        border-bottom: solid 6px #00acbd;
        transform: rotate(45deg);
        position: absolute;
        left: 50%;
        top: 0;
        margin-left: -23px;
        margin-top: -20px; }
      @media screen and (max-width: 769px) {
        .page-medicalexamination .contact-block .arrow {
          height: 20px;
          margin: 20px auto; }
          .page-medicalexamination .contact-block .arrow:before {
            content: "";
            width: 24px;
            height: 24px;
            border-right: solid 3px #00acbd;
            border-bottom: solid 3px #00acbd;
            margin-left: -12px;
            margin-top: -10px; } }
    .page-medicalexamination .contact-block .personal {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 500px;
      margin-top: 20px;
      margin-left: auto;
      margin-right: auto;
      font-size: 30px;
      font-size: 3rem; }
      .page-medicalexamination .contact-block .personal li {
        background: rgba(0, 172, 189, 0.1);
        margin: 12px 0;
        width: 240px;
        padding: 10px 5px 10px 10px;
        border-radius: 5px;
        text-align: left;
        box-sizing: border-box; }
        .page-medicalexamination .contact-block .personal li .num {
          display: inline-block;
          color: #00acbd;
          margin-right: 0.1em; }
        .page-medicalexamination .contact-block .personal li .ti {
          display: inline-block;
          text-indent: -0.5em; }
      @media screen and (max-width: 769px) {
        .page-medicalexamination .contact-block .personal {
          margin-top: 10px;
          justify-content: center;
          width: 100%;
          font-size: 16.5px;
          font-size: 1.65rem; }
          .page-medicalexamination .contact-block .personal li {
            width: 140px;
            margin: 6px 5px;
            padding: 10px 4px 10px 8px;
            line-height: 1; } }
    .page-medicalexamination .contact-block .points {
      background: rgba(0, 172, 189, 0.1);
      width: 900px;
      margin: 20px auto 0;
      padding: 15px 0;
      border-radius: 7px;
      text-align: center; }
      .page-medicalexamination .contact-block .points div {
        display: inline-block; }
      .page-medicalexamination .contact-block .points p {
        text-align: left;
        margin: 5px 0;
        font-size: 30px;
        font-size: 3rem; }
        .page-medicalexamination .contact-block .points p span {
          color: #00acbd; }
      @media screen and (max-width: 769px) {
        .page-medicalexamination .contact-block .points {
          width: 100%;
          margin: 10px auto 0;
          padding: 5px 15px;
          box-sizing: border-box; }
          .page-medicalexamination .contact-block .points p {
            font-size: 18px;
            font-size: 1.8rem; } }
    .page-medicalexamination .contact-block .dotline {
      width: 100%;
      margin: 40px 0 50px;
      height: 2px;
      background-image: linear-gradient(to right, #00acbd, #00acbd 2px, transparent 2px, transparent 10px);
      background-size: 10px 2px;
      background-repeat: repeat-x; }
      @media screen and (max-width: 769px) {
        .page-medicalexamination .contact-block .dotline {
          height: 1px;
          margin: 20px 0 30px;
          background-image: linear-gradient(to right, #00acbd, #00acbd 1px, transparent 1px, transparent 5px);
          background-size: 5px 1px; } }
    @media screen and (max-width: 769px) {
      .page-medicalexamination .contact-block .tel-info__item .note {
        font-size: 12px;
        font-size: 1.2rem; } }

.page-news .kv {
  background: url(../img/news/key_visual.png) no-repeat center center;
  background-size: cover;
  height: auto;
  padding-top: 27%; }

.page-news .content {
  margin: 120px 0; }
  @media screen and (max-width: 769px) {
    .page-news .content {
      margin: 60px 0; } }

.page-news .post-cont {
  font-size: 18px; }
  @media screen and (max-width: 769px) {
    .page-news .post-cont {
      font-size: 16px; } }
  .page-news .post-cont a {
    color: #38acbd;
    border-bottom: 1px solid #38acbd; }

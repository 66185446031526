@mixin circle_num($size, $color) {
  content:"";
  display: inline-block;
  background-color: $color;
  color: $white;
  width: $size;
  height: $size;
  margin-right: 0.6em;
  border-radius: $size;
  font-weight: normal;
  line-height: $size - 2;
  text-align: center;
  font-size: $size - 6;
  position: relative;
  top: -2px;
}
.page-medicalexamination {
  letter-spacing: 0.11em;
  .kv {
    background: url(../img/medicalexamination/key_visual.png) no-repeat center center;
    background-size: cover;
    width: 100%;
  }
  .wrap {
    @include pc-screen {
      width: 980px;
      margin-left: auto;
      margin-right: auto;
    }
    @include sp-screen {
      width: auto;
      margin-left: 5%;
      margin-right: 5%;
    }
  }
  .content {
    @include rem-font-size(20);
    padding-bottom: 50px;
    @include sp-screen {
      @include rem-font-size(14);
      padding-bottom: 0;
    }
    &__ttl {
      padding: 45px 0 30px;
      text-align: center;
      @include rem-font-size(36);
      font-weight: normal;
      letter-spacing: 0.12em;
      @include sp-screen {
        padding: 15px 0;
        @include rem-font-size(21);
      }
    }
    &__lead { 
      width: 750px;
      margin-left: auto;
      margin-right: auto;
      @include sp-screen {
        width: 100%;
        padding: 0 5%;
        box-sizing: border-box;
      }
    }
    &__navi {
      a {
        &:nth-of-type(1) {
          &:before {
            background-image: url(../img/medicalexamination/icon_01.png);
          }
          span { padding-left: 10px; }
        }
        &:nth-of-type(2) {
          letter-spacing: 0.07em;
          &:before {
            background-image: url(../img/medicalexamination/icon_02.png);
          }
          span { padding-left: 20px; }
        }
        &:nth-of-type(3) {
          &:before {
            width: 25px;
            background-image: url(../img/medicalexamination/icon_03.png);
          }
          span { padding-left: 20px; }
        }
        &:nth-of-type(4) {
          &:before {
            width: 25px;
            background-image: url(../img/medicalexamination/icon_04.png);
          }
          span { padding-left: 20px; }
        }
      }
      @include sp-screen {
        a {
          &:nth-of-type(1) {
            &:before {
              width: 23px;
            }
          }
          &:nth-of-type(2) {
            &:before {
              width: 18px;
            }
            span {
              padding-left: 30px;
            }
          }
          &:nth-of-type(3) {
            &:before {
              width: 17px;
            }
            span {
              padding-left: 10px;
            }
          }
          &:nth-of-type(4) {
            &:before {
              width: 17px;
            }
          }
        }
      }
    }
  }
  .tel-info {
    width: 750px;
    box-sizing: border-box;
    margin: 30px auto 50px;
    padding: 20px;
    border-radius: 7px;
    line-height: 1.4;
    background: rgba($mainColor, 0.1);
    @include rem-font-size(50);
    @include sp-screen {
      width: auto;
      margin-bottom: 30px;
      padding: 15px 5%;
      @include rem-font-size(30);
    }
    .tti {
      text-align: center;
      @include rem-font-size(21);
    }
    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      .note {
        @include rem-font-size(17);
      }
    }
    .item--tel {
      &::before {
        content: "";
        display: block;
        width: 56px;
        height: 56px;
        margin-right: 30px;
        background: url(../img/visiting_care/icon_tel.png) no-repeat;
        background-size: contain;
        @include sp-screen {
          width: 28px;
          height: 28px;
          margin-right: 5%;
        }
      }
      a {
        display: block;
        span {
          display: block;
          font-size: 12px;
        }
      }
    }
    @include sp-screen {
      border-radius: 0px;
      .tti {
        @include rem-font-size(11);
      }
      &__item {
        .note {
          @include rem-font-size(12);
        }
      }
    }
  }
  .feature-block {
    background: rgba($mainColor, 0.1);
    padding-bottom: 100px;
    ul {
      margin-top: 26px;
      li {
        margin-top: 24px;
        padding: 30px;
        background: $white;
        border-radius: 10px;
        // &:nth-child(1) h3:before {
        //   content:"1";
        // }
        // &:nth-child(2) h3:before {
        //   content:"2";
        // }
        h3 {
          @include rem-font-size(30);
          font-weight: bold;
          color: $mainColor;
          // &:before {
          //   @include circle_num(30px, $mainColor);
          // }
        }
        .txt {
          margin-top: 15px;
          @include rem-font-size(18);
          .arrow {
            display: inline-block;
            margin-right: 0.5em;
            color: $mainColor;
            font-size: 80%;
          }
        }
      }
    }
    @include sp-screen {
      padding-bottom: 40px;
      figure {
        img {
          max-width: 275px;
          margin-left: auto;
          margin-right: auto;
        }
      }
      ul {
        li {
          padding: 15px;
          h3 {
            text-align: center;
            @include rem-font-size(21);
            line-height: 1.3;
          }
          .txt {
            margin-top: 10px;
            @include rem-font-size(14);
          }
        }
      }
    }
  }
  .menu-block {
    padding-bottom: 150px;
    @include sp-screen {
      padding-bottom: 60px;
    }
    p {
      padding: 0 25px;
      @include sp-screen {
        padding: 0;
      }
    }
    h3 {
      margin-top: 60px;
      margin-bottom: 40px;
      text-align: center;
      @include rem-font-size(36);
      @include sp-screen {
        margin-top: 30px;
        margin-bottom: 10px;
        @include rem-font-size(21);
      }
    }
    .price-time {
      background: rgba(#88c23f, 0.25);
      border-radius: 10px;
      margin-top: 20px;
      padding: 30px;
      position: relative;
      &:before {
        content:"";
        width: calc(100% - 8px);
        height: calc(100% - 8px);
        position: absolute;
        left: 4px;
        top: 4px;
        border: solid 1px $white;
        border-radius: 6px;
        box-sizing: border-box;
      }
      &__in {
        display: flex;
        justify-content: center;
        position: relative;
        z-index: 1;
        li {
          display: flex;
          align-items: center;
          line-height: 1;
          &:first-child {
            margin-right: 50px;
          }
          .item {
            background: #88c23f;
            color: $white;
            margin-right: 30px;
            padding: 8px 10px 10px;
            @include rem-font-size(24);
            border-radius: 4px;
            line-height: 20px;
          }
          .big {
            @include rem-font-size(36);
            margin: 0 0.2em;
          }
          .sml {
            @include rem-font-size(18);
            letter-spacing: 0;
          }
        }
      }
      @include sp-screen {
        padding: 15px;
        border-radius: 6px;
        &:before {
          content:"";
          width: calc(100% - 4px);
          height: calc(100% - 4px);
          left: 2px;
          top: 2px;
          border-radius: 4px;
        }
        &__in {
          li {
            &:first-child {
              margin-right: 20px;
            }
            .item {
              margin-right: 10px;
              padding: 4px 6px 6px;
              @include rem-font-size(12);
              border-radius: 2px;
              line-height: 12px;
            }
            .big {
              @include rem-font-size(18);
              margin: 0 0.2em;
            }
            .sml {
              @include rem-font-size(10);
              letter-spacing: 0;
            }
          }
        }
      }
    }
    .acc-block {
      margin-top: 50px;
      &.light-green {
        .table-title {
          background: #88c23f;
        }
      }
      @include sp-screen {
        margin-top: 25px;
      }
    }
    .table-title {
      color: $white;
      text-align: center;
      height: 50px;
      line-height: 48px;
      @include rem-font-size(24);
      position: relative;
      cursor: pointer;
      &.is-open {
        span {
          &.open {
            display: none;
          }
          &.close {
            display: block;
          }
        }
      }
      span {
        display: none;
        @include rem-font-size(20);
        padding-right: 30px;
        position: absolute;
        right: 20px;
        top: 0;
        &.open {
          display: block;
          &:after {
            transform: rotate(-45deg);
          }
        }
        &.close {
          &:after {
            transform: rotate(45deg);
            margin-top: -7px;
          }
        }
        &:after {
          content: "";
          width: 10px;
          height: 10px;
          border-right: solid 3px $white;
          border-bottom: solid 3px $white;
          position: absolute;
          right: 0px;
          top: 50%;
          margin-top: -5px;
        }
      }
      @include sp-screen {
        display: none;
      }
    }
    .table-wrap {
      display: none;
      .sp-title {
        display: none;
      }
      table {
        .width-set {
          width: 180px;
        }
      }
      @include sp-screen {
        display: block;
        .sp-title {
          display: block;
          background: #88c23f;
          color: $white;
          @include rem-font-size(14);
          padding: 5px 10px;
          border-top: solid 1px #796a56;
          border-right: solid 1px #796a56;
          border-left: solid 1px #796a56;
          width: 600px;
          box-sizing: border-box;
        }
        table {
          width: 600px;
          .width-set {
            width: 100px;
          }
        }
      }
    }
  }
  .table-wrap {
    table {
      width: 100%;
      border: solid 1px #796a56;
      &.green {
        th {
          background: #008e5c;
          color: $white;
          text-align: left;
        }
      }
      th,td {
        background: $white;
        padding: 6px 10px;
        @include rem-font-size(20);
        vertical-align: top;
        letter-spacing: 0;
        border-bottom: solid 1px #796a56;
        &.center {
          text-align: center;
        }
        &:empty {
          background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHN0eWxlPSJ3aWR0aDoxMDAlO2hlaWdodDoxMDAlOyI+PGxpbmUgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMTAwJSIgc3R5bGU9InN0cm9rZTogIzMzMzMzMztzdHJva2Utd2lkdGg6IDE7Ij48L2xpbmU+PC9zdmc+');
        }
      }
      th {
        border-right: none;
      }
      td {
        border-right: solid 1px #796a56;
      }
    }
    @include sp-screen {
      overflow: scroll;
      -webkit-overflow-scrolling: touch;
      overflow-scrolling: touch;
      -webkit-text-size-adjust: 100%;
      table {
        th,td {
          @include rem-font-size(12);
        }
      }
    }
  }
  .option-block {
    background: #e5f7f8;
    padding-bottom: 100px;
    p {
      padding: 0 25px;
    }
    .table-wrap {
      margin-top: 10px;
      margin-bottom: 30px;
      table {
        th,td {
          &:last-child {
            text-align: right;
            padding: 6px 15px;
          }
        }
        .width-set {
          width: 150px;
        }
      }
    }
    @include sp-screen {
      padding-bottom: 50px;
      p {
        padding: 0;
      }
      .table-wrap {
        table {
          &.table1 {
            width: 460px;
          }
          &.table2 {
            width: 610px;
          }
          .width-set {
            width: 80px;
          }
        }
      }
    }
  }
  .contact-block {
    text-align: center;
    h3 {
      position: relative;
      text-align: center;
      &:before {
        content:"";
        width: 100%;
        height: 2px;
        background-image: linear-gradient(to right, $mainColor, $mainColor 2px, transparent 2px, transparent 10px);
        background-size: 10px 2px;
        background-repeat: repeat-x;
        position: absolute;
        left: 0;
        top: 50%;
        z-index: 0;
      }
      span {
        display: inline-block;
        background: $white;
        padding: 0 1em;
        @include rem-font-size(36);
        line-height: 1;
        position: relative;
        z-index: 1;
      }
      @include sp-screen {
        &:before {
          background-image: linear-gradient(to right, $mainColor, $mainColor 1px, transparent 1px, transparent 5px);
          background-size: 5px 1px;
        }
        span {
          @include rem-font-size(21);
        }
      }
    }
    .lead {
      margin-top: 30px;
      @include rem-font-size(30);
      .color {
        color: $mainColor;
      }
      @include sp-screen {
        margin-top: 15px;
        text-align: left;
        @include rem-font-size(18);
      }
    }
    .note {
      @include rem-font-size(20);
      @include sp-screen {
        @include rem-font-size(12);
      }
    }
    table {
      width: 100%;
      max-width: 800px;
      margin-top: 30px;
      margin-left: auto;
      margin-right: auto;
      border: solid 1px #6f6f6f;
      th,td {
        border-right: solid 1px #6f6f6f;
        border-bottom: solid 1px #6f6f6f;
        padding: 15px 20px;
        text-align: center;
        line-height: 1.5;
      }
      th {
        background: $mainColor;
        color: $white;
        @include rem-font-size(22);
        width: 11.5%;
        &:first-child {
          width: 31%;
        }
      }
      td {
        @include rem-font-size(36);
        color: $mainColor;
        &:first-child {
          @include rem-font-size(25);
          text-align: left;
          color: $black;
        }
        .sml {
          color: #6d6d6d;
          @include rem-font-size(22);
        }
      }
      @include sp-screen {
        margin-top: 15px;
        th,td {
          padding: 6px;
          letter-spacing: 0;
        }
        th {
          @include rem-font-size(14);
        }
        td {
          @include rem-font-size(18);
          &:first-child {
            @include rem-font-size(13.5);
          }
          .sml {
            @include rem-font-size(10);
          }
        }
      }
    }
    .arrow {
      width: 100%;
      height: 40px;
      margin: 40px auto;
      position: relative;
      &:before {
        content:"";
        width: 46px;
        height: 46px;
        border-right: solid 6px $mainColor;
        border-bottom: solid 6px $mainColor;
        transform: rotate(45deg);
        position: absolute;
        left: 50%;
        top: 0;
        margin-left: -23px;
        margin-top: -20px;
      }
      @include sp-screen {
        height: 20px;
        margin: 20px auto;
        &:before {
          content:"";
          width: 24px;
          height: 24px;
          border-right: solid 3px $mainColor;
          border-bottom: solid 3px $mainColor;
          margin-left: -12px;
          margin-top: -10px;
        }
      }
    }
    .personal {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 500px;
      margin-top: 20px;
      margin-left: auto;
      margin-right: auto;
      @include rem-font-size(30);
      li {
        background: rgba($mainColor, 0.1);
        margin: 12px 0;
        width: 240px;
        padding: 10px 5px 10px 10px;
        border-radius: 5px;
        text-align: left;
        box-sizing: border-box;
        .num {
          display: inline-block;
          color: $mainColor;
          margin-right: 0.1em;
        }
        .ti {
          display: inline-block;
          text-indent: -0.5em;
        }
      }
      @include sp-screen {
        margin-top: 10px;
        justify-content: center;
        width: 100%;
        @include rem-font-size(16.5);
        li {
          width: 140px;
          margin: 6px 5px;
          padding: 10px 4px 10px 8px;
          line-height: 1;
        }
      }
    }
    .points {
      background: rgba($mainColor, 0.1);
      width: 900px;
      margin: 20px auto 0;
      padding: 15px 0;
      border-radius: 7px;
      text-align: center;
      div {
        display: inline-block;
      }
      p {
        text-align: left;
        margin: 5px 0;
        @include rem-font-size(30);
        span {
          color: $mainColor;
        }
      }
      @include sp-screen {
        width: 100%;
        margin: 10px auto 0;
        padding: 5px 15px;
        box-sizing: border-box;
        p {
          @include rem-font-size(18);
        }
      }
    }
    .dotline {
      width: 100%;
      margin: 40px 0 50px;
      height: 2px;
      background-image: linear-gradient(to right, $mainColor, $mainColor 2px, transparent 2px, transparent 10px);
      background-size: 10px 2px;
      background-repeat: repeat-x;
      @include sp-screen {
        height: 1px;
        margin: 20px 0 30px;
        background-image: linear-gradient(to right, $mainColor, $mainColor 1px, transparent 1px, transparent 5px);
        background-size: 5px 1px;
      }
    }
    @include sp-screen {
      .tel-info {
        &__item {
          .note {
            @include rem-font-size(12);
          }
        }
      }
    }
  }
}
